@charset "UTF-8";
/*!
   * Bootstrap  v5.3.2 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-light: #d0fcfd;
  --bs-dark: #0b0814;
  --bs-primary: #153a5e;
  --bs-secondary: #f37921;
  --bs-info: #4f75a9;
  --bs-success: #51980e;
  --bs-warning: #ffbb49;
  --bs-danger: #eb0660;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-light-rgb: 208, 252, 253;
  --bs-dark-rgb: 11, 8, 20;
  --bs-primary-rgb: 21, 58, 94;
  --bs-secondary-rgb: 243, 121, 33;
  --bs-info-rgb: 79, 117, 169;
  --bs-success-rgb: 81, 152, 14;
  --bs-warning-rgb: 255, 187, 73;
  --bs-danger-rgb: 235, 6, 96;
  --bs-primary-text-emphasis: #081726;
  --bs-secondary-text-emphasis: #61300d;
  --bs-success-text-emphasis: #203d06;
  --bs-info-text-emphasis: #202f44;
  --bs-warning-text-emphasis: #664b1d;
  --bs-danger-text-emphasis: #5e0226;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d0d8df;
  --bs-secondary-bg-subtle: #fde4d3;
  --bs-success-bg-subtle: #dceacf;
  --bs-info-bg-subtle: #dce3ee;
  --bs-warning-bg-subtle: #fff1db;
  --bs-danger-bg-subtle: #fbcddf;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a1b0bf;
  --bs-secondary-border-subtle: #fac9a6;
  --bs-success-border-subtle: #b9d69f;
  --bs-info-border-subtle: #b9c8dd;
  --bs-warning-border-subtle: #ffe4b6;
  --bs-danger-border-subtle: #f79bbf;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #153a5e;
  --bs-link-color-rgb: 21, 58, 94;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #112e4b;
  --bs-link-hover-color-rgb: 17, 46, 75;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(21, 58, 94, 0.25);
  --bs-form-valid-color: #51980e;
  --bs-form-valid-border-color: #51980e;
  --bs-form-invalid-color: #eb0660;
  --bs-form-invalid-border-color: #eb0660; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #73899e;
  --bs-secondary-text-emphasis: #f8af7a;
  --bs-success-text-emphasis: #97c16e;
  --bs-info-text-emphasis: #95accb;
  --bs-warning-text-emphasis: #ffd692;
  --bs-danger-text-emphasis: #f36aa0;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #040c13;
  --bs-secondary-bg-subtle: #311807;
  --bs-success-bg-subtle: #101e03;
  --bs-info-bg-subtle: #101722;
  --bs-warning-bg-subtle: #33250f;
  --bs-danger-bg-subtle: #2f0113;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #0d2338;
  --bs-secondary-border-subtle: #924914;
  --bs-success-border-subtle: #315b08;
  --bs-info-border-subtle: #2f4665;
  --bs-warning-border-subtle: #99702c;
  --bs-danger-border-subtle: #8d043a;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #73899e;
  --bs-link-hover-color: #8fa1b1;
  --bs-link-color-rgb: 115, 137, 158;
  --bs-link-hover-color-rgb: 143, 161, 177;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color); }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline; }
  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d0d8df;
  --bs-table-border-color: #a6adb2;
  --bs-table-striped-bg: #c6cdd4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbc2c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0c8ce;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #fde4d3;
  --bs-table-border-color: #cab6a9;
  --bs-table-striped-bg: #f0d9c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4cdbe;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ead3c3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #dceacf;
  --bs-table-border-color: #b0bba6;
  --bs-table-striped-bg: #d1dec5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6d3ba;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccd8bf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #dce3ee;
  --bs-table-border-color: #b0b6be;
  --bs-table-striped-bg: #d1d8e2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6ccd6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccd2dc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff1db;
  --bs-table-border-color: #ccc1af;
  --bs-table-striped-bg: #f2e5d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d9c5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdfcb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fbcddf;
  --bs-table-border-color: #c9a4b2;
  --bs-table-striped-bg: #eec3d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2b9c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8bece;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #d0fcfd;
  --bs-table-border-color: #a6caca;
  --bs-table-striped-bg: #c6eff0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbe3e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0e9ea;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #0b0814;
  --bs-table-border-color: #3c3943;
  --bs-table-striped-bg: #171420;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #23212c;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #1d1b26;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #8a9daf;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25); }
  .form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0; }
  .form-control::-webkit-datetime-edit {
    display: block;
    padding: 0; }
  .form-control::-moz-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg); }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: var(--bs-border-radius); }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2)); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2)); }

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #8a9daf;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #8a9daf;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25); }
  .form-check-input:checked {
    background-color: #153a5e;
    border-color: #153a5e; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #153a5e;
    border-color: #153a5e;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238a9daf'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(21, 58, 94, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(21, 58, 94, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    -webkit-appearance: none;
            appearance: none;
    background-color: #153a5e;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b9c4cf; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    -moz-appearance: none;
         appearance: none;
    background-color: #153a5e;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b9c4cf; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
      color: transparent; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
      position: absolute;
      inset: 1rem 0.375rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.375rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label,
  .form-floating > .form-control:disabled ~ label {
    color: #6c757d; }
    .form-floating > :disabled ~ label::after,
    .form-floating > .form-control:disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2351980e' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2351980e' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23eb0660'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23eb0660' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23eb0660'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23eb0660' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff; }

.btn-black {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #d0fcfd;
  --bs-btn-border-color: #d0fcfd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b1d6d7;
  --bs-btn-hover-border-color: #a6caca;
  --bs-btn-focus-shadow-rgb: 177, 214, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #a6caca;
  --bs-btn-active-border-color: #9cbdbe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d0fcfd;
  --bs-btn-disabled-border-color: #d0fcfd; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0b0814;
  --bs-btn-border-color: #0b0814;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #302d37;
  --bs-btn-hover-border-color: #23212c;
  --bs-btn-focus-shadow-rgb: 48, 45, 55;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3c3943;
  --bs-btn-active-border-color: #23212c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0b0814;
  --bs-btn-disabled-border-color: #0b0814; }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #153a5e;
  --bs-btn-border-color: #153a5e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #123150;
  --bs-btn-hover-border-color: #112e4b;
  --bs-btn-focus-shadow-rgb: 56, 88, 118;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #112e4b;
  --bs-btn-active-border-color: #102c47;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #153a5e;
  --bs-btn-disabled-border-color: #153a5e; }

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #f37921;
  --bs-btn-border-color: #f37921;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f58d42;
  --bs-btn-hover-border-color: #f48637;
  --bs-btn-focus-shadow-rgb: 207, 103, 28;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5944d;
  --bs-btn-active-border-color: #f48637;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f37921;
  --bs-btn-disabled-border-color: #f37921; }

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4f75a9;
  --bs-btn-border-color: #4f75a9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #436390;
  --bs-btn-hover-border-color: #3f5e87;
  --bs-btn-focus-shadow-rgb: 105, 138, 182;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3f5e87;
  --bs-btn-active-border-color: #3b587f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4f75a9;
  --bs-btn-disabled-border-color: #4f75a9; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #51980e;
  --bs-btn-border-color: #51980e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6ba732;
  --bs-btn-hover-border-color: #62a226;
  --bs-btn-focus-shadow-rgb: 69, 129, 12;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #74ad3e;
  --bs-btn-active-border-color: #62a226;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #51980e;
  --bs-btn-disabled-border-color: #51980e; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffbb49;
  --bs-btn-border-color: #ffbb49;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc564;
  --bs-btn-hover-border-color: #ffc25b;
  --bs-btn-focus-shadow-rgb: 217, 159, 62;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc96d;
  --bs-btn-active-border-color: #ffc25b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffbb49;
  --bs-btn-disabled-border-color: #ffbb49; }

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #eb0660;
  --bs-btn-border-color: #eb0660;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ee2b78;
  --bs-btn-hover-border-color: #ed1f70;
  --bs-btn-focus-shadow-rgb: 200, 5, 82;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ef3880;
  --bs-btn-active-border-color: #ed1f70;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #eb0660;
  --bs-btn-disabled-border-color: #eb0660; }

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none; }

.btn-outline-black {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #d0fcfd;
  --bs-btn-border-color: #d0fcfd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d0fcfd;
  --bs-btn-hover-border-color: #d0fcfd;
  --bs-btn-focus-shadow-rgb: 208, 252, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d0fcfd;
  --bs-btn-active-border-color: #d0fcfd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d0fcfd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d0fcfd;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #0b0814;
  --bs-btn-border-color: #0b0814;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b0814;
  --bs-btn-hover-border-color: #0b0814;
  --bs-btn-focus-shadow-rgb: 11, 8, 20;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0b0814;
  --bs-btn-active-border-color: #0b0814;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0b0814;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0b0814;
  --bs-gradient: none; }

.btn-outline-primary {
  --bs-btn-color: #153a5e;
  --bs-btn-border-color: #153a5e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #153a5e;
  --bs-btn-hover-border-color: #153a5e;
  --bs-btn-focus-shadow-rgb: 21, 58, 94;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #153a5e;
  --bs-btn-active-border-color: #153a5e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #153a5e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #153a5e;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #f37921;
  --bs-btn-border-color: #f37921;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f37921;
  --bs-btn-hover-border-color: #f37921;
  --bs-btn-focus-shadow-rgb: 243, 121, 33;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f37921;
  --bs-btn-active-border-color: #f37921;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f37921;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f37921;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #4f75a9;
  --bs-btn-border-color: #4f75a9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4f75a9;
  --bs-btn-hover-border-color: #4f75a9;
  --bs-btn-focus-shadow-rgb: 79, 117, 169;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4f75a9;
  --bs-btn-active-border-color: #4f75a9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4f75a9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4f75a9;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #51980e;
  --bs-btn-border-color: #51980e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #51980e;
  --bs-btn-hover-border-color: #51980e;
  --bs-btn-focus-shadow-rgb: 81, 152, 14;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #51980e;
  --bs-btn-active-border-color: #51980e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #51980e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #51980e;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffbb49;
  --bs-btn-border-color: #ffbb49;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffbb49;
  --bs-btn-hover-border-color: #ffbb49;
  --bs-btn-focus-shadow-rgb: 255, 187, 73;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffbb49;
  --bs-btn-active-border-color: #ffbb49;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffbb49;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffbb49;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #eb0660;
  --bs-btn-border-color: #eb0660;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eb0660;
  --bs-btn-hover-border-color: #eb0660;
  --bs-btn-focus-shadow-rgb: 235, 6, 96;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #eb0660;
  --bs-btn-active-border-color: #eb0660;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eb0660;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eb0660;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 56, 88, 118;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg); }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm); }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #153a5e;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #153a5e;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: var(--bs-border-radius); }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: calc(var(--bs-border-width) * -1); }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1); }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25); }
  .nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #153a5e; }
  .nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius); }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap); }
  .nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent; }
    .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
      border-bottom-color: currentcolor; }
  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color); }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23081726'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8a9daf;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2373899e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2373899e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #153a5e;
  --bs-pagination-active-border-color: #153a5e;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color); }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis); }

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis); }

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis); }

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis); }

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis); }

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis); }

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis); }

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis); }

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis); }

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis); }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #153a5e;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #153a5e;
  --bs-list-group-active-border-color: #153a5e;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis); }

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(21, 58, 94, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important; }

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-white:hover, .link-white:focus {
    color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important; }

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-black:hover, .link-black:focus {
    color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(217, 253, 253, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(217, 253, 253, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(9, 6, 16, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(9, 6, 16, var(--bs-link-underline-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(17, 46, 75, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(17, 46, 75, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(245, 148, 77, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(245, 148, 77, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(63, 94, 135, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(63, 94, 135, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(116, 173, 62, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(116, 173, 62, var(--bs-link-underline-opacity, 1)) !important; }

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-warning:hover, .link-warning:focus {
    color: RGBA(255, 201, 109, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 201, 109, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(239, 56, 128, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(239, 56, 128, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0)); }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important; }

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important; }

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important; }

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important; }

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: var(--bs-box-shadow) !important; }

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important; }

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important; }

.shadow-none {
  box-shadow: none !important; }

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.row-gap-0 {
  row-gap: 0 !important; }

.row-gap-1 {
  row-gap: 0.25rem !important; }

.row-gap-2 {
  row-gap: 0.5rem !important; }

.row-gap-3 {
  row-gap: 1rem !important; }

.row-gap-4 {
  row-gap: 1.5rem !important; }

.row-gap-5 {
  row-gap: 3rem !important; }

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important; }

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important; }

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important; }

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important; }

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important; }

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important; }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important; }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important; }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important; }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .row-gap-sm-0 {
    row-gap: 0 !important; }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    row-gap: 3rem !important; }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important; }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important; }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important; }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important; }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important; }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important; }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .row-gap-md-0 {
    row-gap: 0 !important; }
  .row-gap-md-1 {
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    row-gap: 3rem !important; }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important; }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important; }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important; }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important; }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important; }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important; }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .row-gap-lg-0 {
    row-gap: 0 !important; }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    row-gap: 3rem !important; }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important; }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important; }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important; }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important; }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important; }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important; }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .row-gap-xl-0 {
    row-gap: 0 !important; }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    row-gap: 3rem !important; }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important; }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important; }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important; }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important; }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important; }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important; }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .row-gap-xxl-0 {
    row-gap: 0 !important; }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    row-gap: 3rem !important; }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important; }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*!
 * Bootstrap Icons v1.11.2 (https://icons.getbootstrap.com/)
 * Copyright 2019-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("../fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("../fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff"); }

.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bi-123::before {
  content: ""; }

.bi-alarm-fill::before {
  content: ""; }

.bi-alarm::before {
  content: ""; }

.bi-align-bottom::before {
  content: ""; }

.bi-align-center::before {
  content: ""; }

.bi-align-end::before {
  content: ""; }

.bi-align-middle::before {
  content: ""; }

.bi-align-start::before {
  content: ""; }

.bi-align-top::before {
  content: ""; }

.bi-alt::before {
  content: ""; }

.bi-app-indicator::before {
  content: ""; }

.bi-app::before {
  content: ""; }

.bi-archive-fill::before {
  content: ""; }

.bi-archive::before {
  content: ""; }

.bi-arrow-90deg-down::before {
  content: ""; }

.bi-arrow-90deg-left::before {
  content: ""; }

.bi-arrow-90deg-right::before {
  content: ""; }

.bi-arrow-90deg-up::before {
  content: ""; }

.bi-arrow-bar-down::before {
  content: ""; }

.bi-arrow-bar-left::before {
  content: ""; }

.bi-arrow-bar-right::before {
  content: ""; }

.bi-arrow-bar-up::before {
  content: ""; }

.bi-arrow-clockwise::before {
  content: ""; }

.bi-arrow-counterclockwise::before {
  content: ""; }

.bi-arrow-down-circle-fill::before {
  content: ""; }

.bi-arrow-down-circle::before {
  content: ""; }

.bi-arrow-down-left-circle-fill::before {
  content: ""; }

.bi-arrow-down-left-circle::before {
  content: ""; }

.bi-arrow-down-left-square-fill::before {
  content: ""; }

.bi-arrow-down-left-square::before {
  content: ""; }

.bi-arrow-down-left::before {
  content: ""; }

.bi-arrow-down-right-circle-fill::before {
  content: ""; }

.bi-arrow-down-right-circle::before {
  content: ""; }

.bi-arrow-down-right-square-fill::before {
  content: ""; }

.bi-arrow-down-right-square::before {
  content: ""; }

.bi-arrow-down-right::before {
  content: ""; }

.bi-arrow-down-short::before {
  content: ""; }

.bi-arrow-down-square-fill::before {
  content: ""; }

.bi-arrow-down-square::before {
  content: ""; }

.bi-arrow-down-up::before {
  content: ""; }

.bi-arrow-down::before {
  content: ""; }

.bi-arrow-left-circle-fill::before {
  content: ""; }

.bi-arrow-left-circle::before {
  content: ""; }

.bi-arrow-left-right::before {
  content: ""; }

.bi-arrow-left-short::before {
  content: ""; }

.bi-arrow-left-square-fill::before {
  content: ""; }

.bi-arrow-left-square::before {
  content: ""; }

.bi-arrow-left::before {
  content: ""; }

.bi-arrow-repeat::before {
  content: ""; }

.bi-arrow-return-left::before {
  content: ""; }

.bi-arrow-return-right::before {
  content: ""; }

.bi-arrow-right-circle-fill::before {
  content: ""; }

.bi-arrow-right-circle::before {
  content: ""; }

.bi-arrow-right-short::before {
  content: ""; }

.bi-arrow-right-square-fill::before {
  content: ""; }

.bi-arrow-right-square::before {
  content: ""; }

.bi-arrow-right::before {
  content: ""; }

.bi-arrow-up-circle-fill::before {
  content: ""; }

.bi-arrow-up-circle::before {
  content: ""; }

.bi-arrow-up-left-circle-fill::before {
  content: ""; }

.bi-arrow-up-left-circle::before {
  content: ""; }

.bi-arrow-up-left-square-fill::before {
  content: ""; }

.bi-arrow-up-left-square::before {
  content: ""; }

.bi-arrow-up-left::before {
  content: ""; }

.bi-arrow-up-right-circle-fill::before {
  content: ""; }

.bi-arrow-up-right-circle::before {
  content: ""; }

.bi-arrow-up-right-square-fill::before {
  content: ""; }

.bi-arrow-up-right-square::before {
  content: ""; }

.bi-arrow-up-right::before {
  content: ""; }

.bi-arrow-up-short::before {
  content: ""; }

.bi-arrow-up-square-fill::before {
  content: ""; }

.bi-arrow-up-square::before {
  content: ""; }

.bi-arrow-up::before {
  content: ""; }

.bi-arrows-angle-contract::before {
  content: ""; }

.bi-arrows-angle-expand::before {
  content: ""; }

.bi-arrows-collapse::before {
  content: ""; }

.bi-arrows-expand::before {
  content: ""; }

.bi-arrows-fullscreen::before {
  content: ""; }

.bi-arrows-move::before {
  content: ""; }

.bi-aspect-ratio-fill::before {
  content: ""; }

.bi-aspect-ratio::before {
  content: ""; }

.bi-asterisk::before {
  content: ""; }

.bi-at::before {
  content: ""; }

.bi-award-fill::before {
  content: ""; }

.bi-award::before {
  content: ""; }

.bi-back::before {
  content: ""; }

.bi-backspace-fill::before {
  content: ""; }

.bi-backspace-reverse-fill::before {
  content: ""; }

.bi-backspace-reverse::before {
  content: ""; }

.bi-backspace::before {
  content: ""; }

.bi-badge-3d-fill::before {
  content: ""; }

.bi-badge-3d::before {
  content: ""; }

.bi-badge-4k-fill::before {
  content: ""; }

.bi-badge-4k::before {
  content: ""; }

.bi-badge-8k-fill::before {
  content: ""; }

.bi-badge-8k::before {
  content: ""; }

.bi-badge-ad-fill::before {
  content: ""; }

.bi-badge-ad::before {
  content: ""; }

.bi-badge-ar-fill::before {
  content: ""; }

.bi-badge-ar::before {
  content: ""; }

.bi-badge-cc-fill::before {
  content: ""; }

.bi-badge-cc::before {
  content: ""; }

.bi-badge-hd-fill::before {
  content: ""; }

.bi-badge-hd::before {
  content: ""; }

.bi-badge-tm-fill::before {
  content: ""; }

.bi-badge-tm::before {
  content: ""; }

.bi-badge-vo-fill::before {
  content: ""; }

.bi-badge-vo::before {
  content: ""; }

.bi-badge-vr-fill::before {
  content: ""; }

.bi-badge-vr::before {
  content: ""; }

.bi-badge-wc-fill::before {
  content: ""; }

.bi-badge-wc::before {
  content: ""; }

.bi-bag-check-fill::before {
  content: ""; }

.bi-bag-check::before {
  content: ""; }

.bi-bag-dash-fill::before {
  content: ""; }

.bi-bag-dash::before {
  content: ""; }

.bi-bag-fill::before {
  content: ""; }

.bi-bag-plus-fill::before {
  content: ""; }

.bi-bag-plus::before {
  content: ""; }

.bi-bag-x-fill::before {
  content: ""; }

.bi-bag-x::before {
  content: ""; }

.bi-bag::before {
  content: ""; }

.bi-bar-chart-fill::before {
  content: ""; }

.bi-bar-chart-line-fill::before {
  content: ""; }

.bi-bar-chart-line::before {
  content: ""; }

.bi-bar-chart-steps::before {
  content: ""; }

.bi-bar-chart::before {
  content: ""; }

.bi-basket-fill::before {
  content: ""; }

.bi-basket::before {
  content: ""; }

.bi-basket2-fill::before {
  content: ""; }

.bi-basket2::before {
  content: ""; }

.bi-basket3-fill::before {
  content: ""; }

.bi-basket3::before {
  content: ""; }

.bi-battery-charging::before {
  content: ""; }

.bi-battery-full::before {
  content: ""; }

.bi-battery-half::before {
  content: ""; }

.bi-battery::before {
  content: ""; }

.bi-bell-fill::before {
  content: ""; }

.bi-bell::before {
  content: ""; }

.bi-bezier::before {
  content: ""; }

.bi-bezier2::before {
  content: ""; }

.bi-bicycle::before {
  content: ""; }

.bi-binoculars-fill::before {
  content: ""; }

.bi-binoculars::before {
  content: ""; }

.bi-blockquote-left::before {
  content: ""; }

.bi-blockquote-right::before {
  content: ""; }

.bi-book-fill::before {
  content: ""; }

.bi-book-half::before {
  content: ""; }

.bi-book::before {
  content: ""; }

.bi-bookmark-check-fill::before {
  content: ""; }

.bi-bookmark-check::before {
  content: ""; }

.bi-bookmark-dash-fill::before {
  content: ""; }

.bi-bookmark-dash::before {
  content: ""; }

.bi-bookmark-fill::before {
  content: ""; }

.bi-bookmark-heart-fill::before {
  content: ""; }

.bi-bookmark-heart::before {
  content: ""; }

.bi-bookmark-plus-fill::before {
  content: ""; }

.bi-bookmark-plus::before {
  content: ""; }

.bi-bookmark-star-fill::before {
  content: ""; }

.bi-bookmark-star::before {
  content: ""; }

.bi-bookmark-x-fill::before {
  content: ""; }

.bi-bookmark-x::before {
  content: ""; }

.bi-bookmark::before {
  content: ""; }

.bi-bookmarks-fill::before {
  content: ""; }

.bi-bookmarks::before {
  content: ""; }

.bi-bookshelf::before {
  content: ""; }

.bi-bootstrap-fill::before {
  content: ""; }

.bi-bootstrap-reboot::before {
  content: ""; }

.bi-bootstrap::before {
  content: ""; }

.bi-border-all::before {
  content: ""; }

.bi-border-bottom::before {
  content: ""; }

.bi-border-center::before {
  content: ""; }

.bi-border-inner::before {
  content: ""; }

.bi-border-left::before {
  content: ""; }

.bi-border-middle::before {
  content: ""; }

.bi-border-outer::before {
  content: ""; }

.bi-border-right::before {
  content: ""; }

.bi-border-style::before {
  content: ""; }

.bi-border-top::before {
  content: ""; }

.bi-border-width::before {
  content: ""; }

.bi-border::before {
  content: ""; }

.bi-bounding-box-circles::before {
  content: ""; }

.bi-bounding-box::before {
  content: ""; }

.bi-box-arrow-down-left::before {
  content: ""; }

.bi-box-arrow-down-right::before {
  content: ""; }

.bi-box-arrow-down::before {
  content: ""; }

.bi-box-arrow-in-down-left::before {
  content: ""; }

.bi-box-arrow-in-down-right::before {
  content: ""; }

.bi-box-arrow-in-down::before {
  content: ""; }

.bi-box-arrow-in-left::before {
  content: ""; }

.bi-box-arrow-in-right::before {
  content: ""; }

.bi-box-arrow-in-up-left::before {
  content: ""; }

.bi-box-arrow-in-up-right::before {
  content: ""; }

.bi-box-arrow-in-up::before {
  content: ""; }

.bi-box-arrow-left::before {
  content: ""; }

.bi-box-arrow-right::before {
  content: ""; }

.bi-box-arrow-up-left::before {
  content: ""; }

.bi-box-arrow-up-right::before {
  content: ""; }

.bi-box-arrow-up::before {
  content: ""; }

.bi-box-seam::before {
  content: ""; }

.bi-box::before {
  content: ""; }

.bi-braces::before {
  content: ""; }

.bi-bricks::before {
  content: ""; }

.bi-briefcase-fill::before {
  content: ""; }

.bi-briefcase::before {
  content: ""; }

.bi-brightness-alt-high-fill::before {
  content: ""; }

.bi-brightness-alt-high::before {
  content: ""; }

.bi-brightness-alt-low-fill::before {
  content: ""; }

.bi-brightness-alt-low::before {
  content: ""; }

.bi-brightness-high-fill::before {
  content: ""; }

.bi-brightness-high::before {
  content: ""; }

.bi-brightness-low-fill::before {
  content: ""; }

.bi-brightness-low::before {
  content: ""; }

.bi-broadcast-pin::before {
  content: ""; }

.bi-broadcast::before {
  content: ""; }

.bi-brush-fill::before {
  content: ""; }

.bi-brush::before {
  content: ""; }

.bi-bucket-fill::before {
  content: ""; }

.bi-bucket::before {
  content: ""; }

.bi-bug-fill::before {
  content: ""; }

.bi-bug::before {
  content: ""; }

.bi-building::before {
  content: ""; }

.bi-bullseye::before {
  content: ""; }

.bi-calculator-fill::before {
  content: ""; }

.bi-calculator::before {
  content: ""; }

.bi-calendar-check-fill::before {
  content: ""; }

.bi-calendar-check::before {
  content: ""; }

.bi-calendar-date-fill::before {
  content: ""; }

.bi-calendar-date::before {
  content: ""; }

.bi-calendar-day-fill::before {
  content: ""; }

.bi-calendar-day::before {
  content: ""; }

.bi-calendar-event-fill::before {
  content: ""; }

.bi-calendar-event::before {
  content: ""; }

.bi-calendar-fill::before {
  content: ""; }

.bi-calendar-minus-fill::before {
  content: ""; }

.bi-calendar-minus::before {
  content: ""; }

.bi-calendar-month-fill::before {
  content: ""; }

.bi-calendar-month::before {
  content: ""; }

.bi-calendar-plus-fill::before {
  content: ""; }

.bi-calendar-plus::before {
  content: ""; }

.bi-calendar-range-fill::before {
  content: ""; }

.bi-calendar-range::before {
  content: ""; }

.bi-calendar-week-fill::before {
  content: ""; }

.bi-calendar-week::before {
  content: ""; }

.bi-calendar-x-fill::before {
  content: ""; }

.bi-calendar-x::before {
  content: ""; }

.bi-calendar::before {
  content: ""; }

.bi-calendar2-check-fill::before {
  content: ""; }

.bi-calendar2-check::before {
  content: ""; }

.bi-calendar2-date-fill::before {
  content: ""; }

.bi-calendar2-date::before {
  content: ""; }

.bi-calendar2-day-fill::before {
  content: ""; }

.bi-calendar2-day::before {
  content: ""; }

.bi-calendar2-event-fill::before {
  content: ""; }

.bi-calendar2-event::before {
  content: ""; }

.bi-calendar2-fill::before {
  content: ""; }

.bi-calendar2-minus-fill::before {
  content: ""; }

.bi-calendar2-minus::before {
  content: ""; }

.bi-calendar2-month-fill::before {
  content: ""; }

.bi-calendar2-month::before {
  content: ""; }

.bi-calendar2-plus-fill::before {
  content: ""; }

.bi-calendar2-plus::before {
  content: ""; }

.bi-calendar2-range-fill::before {
  content: ""; }

.bi-calendar2-range::before {
  content: ""; }

.bi-calendar2-week-fill::before {
  content: ""; }

.bi-calendar2-week::before {
  content: ""; }

.bi-calendar2-x-fill::before {
  content: ""; }

.bi-calendar2-x::before {
  content: ""; }

.bi-calendar2::before {
  content: ""; }

.bi-calendar3-event-fill::before {
  content: ""; }

.bi-calendar3-event::before {
  content: ""; }

.bi-calendar3-fill::before {
  content: ""; }

.bi-calendar3-range-fill::before {
  content: ""; }

.bi-calendar3-range::before {
  content: ""; }

.bi-calendar3-week-fill::before {
  content: ""; }

.bi-calendar3-week::before {
  content: ""; }

.bi-calendar3::before {
  content: ""; }

.bi-calendar4-event::before {
  content: ""; }

.bi-calendar4-range::before {
  content: ""; }

.bi-calendar4-week::before {
  content: ""; }

.bi-calendar4::before {
  content: ""; }

.bi-camera-fill::before {
  content: ""; }

.bi-camera-reels-fill::before {
  content: ""; }

.bi-camera-reels::before {
  content: ""; }

.bi-camera-video-fill::before {
  content: ""; }

.bi-camera-video-off-fill::before {
  content: ""; }

.bi-camera-video-off::before {
  content: ""; }

.bi-camera-video::before {
  content: ""; }

.bi-camera::before {
  content: ""; }

.bi-camera2::before {
  content: ""; }

.bi-capslock-fill::before {
  content: ""; }

.bi-capslock::before {
  content: ""; }

.bi-card-checklist::before {
  content: ""; }

.bi-card-heading::before {
  content: ""; }

.bi-card-image::before {
  content: ""; }

.bi-card-list::before {
  content: ""; }

.bi-card-text::before {
  content: ""; }

.bi-caret-down-fill::before {
  content: ""; }

.bi-caret-down-square-fill::before {
  content: ""; }

.bi-caret-down-square::before {
  content: ""; }

.bi-caret-down::before {
  content: ""; }

.bi-caret-left-fill::before {
  content: ""; }

.bi-caret-left-square-fill::before {
  content: ""; }

.bi-caret-left-square::before {
  content: ""; }

.bi-caret-left::before {
  content: ""; }

.bi-caret-right-fill::before {
  content: ""; }

.bi-caret-right-square-fill::before {
  content: ""; }

.bi-caret-right-square::before {
  content: ""; }

.bi-caret-right::before {
  content: ""; }

.bi-caret-up-fill::before {
  content: ""; }

.bi-caret-up-square-fill::before {
  content: ""; }

.bi-caret-up-square::before {
  content: ""; }

.bi-caret-up::before {
  content: ""; }

.bi-cart-check-fill::before {
  content: ""; }

.bi-cart-check::before {
  content: ""; }

.bi-cart-dash-fill::before {
  content: ""; }

.bi-cart-dash::before {
  content: ""; }

.bi-cart-fill::before {
  content: ""; }

.bi-cart-plus-fill::before {
  content: ""; }

.bi-cart-plus::before {
  content: ""; }

.bi-cart-x-fill::before {
  content: ""; }

.bi-cart-x::before {
  content: ""; }

.bi-cart::before {
  content: ""; }

.bi-cart2::before {
  content: ""; }

.bi-cart3::before {
  content: ""; }

.bi-cart4::before {
  content: ""; }

.bi-cash-stack::before {
  content: ""; }

.bi-cash::before {
  content: ""; }

.bi-cast::before {
  content: ""; }

.bi-chat-dots-fill::before {
  content: ""; }

.bi-chat-dots::before {
  content: ""; }

.bi-chat-fill::before {
  content: ""; }

.bi-chat-left-dots-fill::before {
  content: ""; }

.bi-chat-left-dots::before {
  content: ""; }

.bi-chat-left-fill::before {
  content: ""; }

.bi-chat-left-quote-fill::before {
  content: ""; }

.bi-chat-left-quote::before {
  content: ""; }

.bi-chat-left-text-fill::before {
  content: ""; }

.bi-chat-left-text::before {
  content: ""; }

.bi-chat-left::before {
  content: ""; }

.bi-chat-quote-fill::before {
  content: ""; }

.bi-chat-quote::before {
  content: ""; }

.bi-chat-right-dots-fill::before {
  content: ""; }

.bi-chat-right-dots::before {
  content: ""; }

.bi-chat-right-fill::before {
  content: ""; }

.bi-chat-right-quote-fill::before {
  content: ""; }

.bi-chat-right-quote::before {
  content: ""; }

.bi-chat-right-text-fill::before {
  content: ""; }

.bi-chat-right-text::before {
  content: ""; }

.bi-chat-right::before {
  content: ""; }

.bi-chat-square-dots-fill::before {
  content: ""; }

.bi-chat-square-dots::before {
  content: ""; }

.bi-chat-square-fill::before {
  content: ""; }

.bi-chat-square-quote-fill::before {
  content: ""; }

.bi-chat-square-quote::before {
  content: ""; }

.bi-chat-square-text-fill::before {
  content: ""; }

.bi-chat-square-text::before {
  content: ""; }

.bi-chat-square::before {
  content: ""; }

.bi-chat-text-fill::before {
  content: ""; }

.bi-chat-text::before {
  content: ""; }

.bi-chat::before {
  content: ""; }

.bi-check-all::before {
  content: ""; }

.bi-check-circle-fill::before {
  content: ""; }

.bi-check-circle::before {
  content: ""; }

.bi-check-square-fill::before {
  content: ""; }

.bi-check-square::before {
  content: ""; }

.bi-check::before {
  content: ""; }

.bi-check2-all::before {
  content: ""; }

.bi-check2-circle::before {
  content: ""; }

.bi-check2-square::before {
  content: ""; }

.bi-check2::before {
  content: ""; }

.bi-chevron-bar-contract::before {
  content: ""; }

.bi-chevron-bar-down::before {
  content: ""; }

.bi-chevron-bar-expand::before {
  content: ""; }

.bi-chevron-bar-left::before {
  content: ""; }

.bi-chevron-bar-right::before {
  content: ""; }

.bi-chevron-bar-up::before {
  content: ""; }

.bi-chevron-compact-down::before {
  content: ""; }

.bi-chevron-compact-left::before {
  content: ""; }

.bi-chevron-compact-right::before {
  content: ""; }

.bi-chevron-compact-up::before {
  content: ""; }

.bi-chevron-contract::before {
  content: ""; }

.bi-chevron-double-down::before {
  content: ""; }

.bi-chevron-double-left::before {
  content: ""; }

.bi-chevron-double-right::before {
  content: ""; }

.bi-chevron-double-up::before {
  content: ""; }

.bi-chevron-down::before {
  content: ""; }

.bi-chevron-expand::before {
  content: ""; }

.bi-chevron-left::before {
  content: ""; }

.bi-chevron-right::before {
  content: ""; }

.bi-chevron-up::before {
  content: ""; }

.bi-circle-fill::before {
  content: ""; }

.bi-circle-half::before {
  content: ""; }

.bi-circle-square::before {
  content: ""; }

.bi-circle::before {
  content: ""; }

.bi-clipboard-check::before {
  content: ""; }

.bi-clipboard-data::before {
  content: ""; }

.bi-clipboard-minus::before {
  content: ""; }

.bi-clipboard-plus::before {
  content: ""; }

.bi-clipboard-x::before {
  content: ""; }

.bi-clipboard::before {
  content: ""; }

.bi-clock-fill::before {
  content: ""; }

.bi-clock-history::before {
  content: ""; }

.bi-clock::before {
  content: ""; }

.bi-cloud-arrow-down-fill::before {
  content: ""; }

.bi-cloud-arrow-down::before {
  content: ""; }

.bi-cloud-arrow-up-fill::before {
  content: ""; }

.bi-cloud-arrow-up::before {
  content: ""; }

.bi-cloud-check-fill::before {
  content: ""; }

.bi-cloud-check::before {
  content: ""; }

.bi-cloud-download-fill::before {
  content: ""; }

.bi-cloud-download::before {
  content: ""; }

.bi-cloud-drizzle-fill::before {
  content: ""; }

.bi-cloud-drizzle::before {
  content: ""; }

.bi-cloud-fill::before {
  content: ""; }

.bi-cloud-fog-fill::before {
  content: ""; }

.bi-cloud-fog::before {
  content: ""; }

.bi-cloud-fog2-fill::before {
  content: ""; }

.bi-cloud-fog2::before {
  content: ""; }

.bi-cloud-hail-fill::before {
  content: ""; }

.bi-cloud-hail::before {
  content: ""; }

.bi-cloud-haze-fill::before {
  content: ""; }

.bi-cloud-haze::before {
  content: ""; }

.bi-cloud-haze2-fill::before {
  content: ""; }

.bi-cloud-lightning-fill::before {
  content: ""; }

.bi-cloud-lightning-rain-fill::before {
  content: ""; }

.bi-cloud-lightning-rain::before {
  content: ""; }

.bi-cloud-lightning::before {
  content: ""; }

.bi-cloud-minus-fill::before {
  content: ""; }

.bi-cloud-minus::before {
  content: ""; }

.bi-cloud-moon-fill::before {
  content: ""; }

.bi-cloud-moon::before {
  content: ""; }

.bi-cloud-plus-fill::before {
  content: ""; }

.bi-cloud-plus::before {
  content: ""; }

.bi-cloud-rain-fill::before {
  content: ""; }

.bi-cloud-rain-heavy-fill::before {
  content: ""; }

.bi-cloud-rain-heavy::before {
  content: ""; }

.bi-cloud-rain::before {
  content: ""; }

.bi-cloud-slash-fill::before {
  content: ""; }

.bi-cloud-slash::before {
  content: ""; }

.bi-cloud-sleet-fill::before {
  content: ""; }

.bi-cloud-sleet::before {
  content: ""; }

.bi-cloud-snow-fill::before {
  content: ""; }

.bi-cloud-snow::before {
  content: ""; }

.bi-cloud-sun-fill::before {
  content: ""; }

.bi-cloud-sun::before {
  content: ""; }

.bi-cloud-upload-fill::before {
  content: ""; }

.bi-cloud-upload::before {
  content: ""; }

.bi-cloud::before {
  content: ""; }

.bi-clouds-fill::before {
  content: ""; }

.bi-clouds::before {
  content: ""; }

.bi-cloudy-fill::before {
  content: ""; }

.bi-cloudy::before {
  content: ""; }

.bi-code-slash::before {
  content: ""; }

.bi-code-square::before {
  content: ""; }

.bi-code::before {
  content: ""; }

.bi-collection-fill::before {
  content: ""; }

.bi-collection-play-fill::before {
  content: ""; }

.bi-collection-play::before {
  content: ""; }

.bi-collection::before {
  content: ""; }

.bi-columns-gap::before {
  content: ""; }

.bi-columns::before {
  content: ""; }

.bi-command::before {
  content: ""; }

.bi-compass-fill::before {
  content: ""; }

.bi-compass::before {
  content: ""; }

.bi-cone-striped::before {
  content: ""; }

.bi-cone::before {
  content: ""; }

.bi-controller::before {
  content: ""; }

.bi-cpu-fill::before {
  content: ""; }

.bi-cpu::before {
  content: ""; }

.bi-credit-card-2-back-fill::before {
  content: ""; }

.bi-credit-card-2-back::before {
  content: ""; }

.bi-credit-card-2-front-fill::before {
  content: ""; }

.bi-credit-card-2-front::before {
  content: ""; }

.bi-credit-card-fill::before {
  content: ""; }

.bi-credit-card::before {
  content: ""; }

.bi-crop::before {
  content: ""; }

.bi-cup-fill::before {
  content: ""; }

.bi-cup-straw::before {
  content: ""; }

.bi-cup::before {
  content: ""; }

.bi-cursor-fill::before {
  content: ""; }

.bi-cursor-text::before {
  content: ""; }

.bi-cursor::before {
  content: ""; }

.bi-dash-circle-dotted::before {
  content: ""; }

.bi-dash-circle-fill::before {
  content: ""; }

.bi-dash-circle::before {
  content: ""; }

.bi-dash-square-dotted::before {
  content: ""; }

.bi-dash-square-fill::before {
  content: ""; }

.bi-dash-square::before {
  content: ""; }

.bi-dash::before {
  content: ""; }

.bi-diagram-2-fill::before {
  content: ""; }

.bi-diagram-2::before {
  content: ""; }

.bi-diagram-3-fill::before {
  content: ""; }

.bi-diagram-3::before {
  content: ""; }

.bi-diamond-fill::before {
  content: ""; }

.bi-diamond-half::before {
  content: ""; }

.bi-diamond::before {
  content: ""; }

.bi-dice-1-fill::before {
  content: ""; }

.bi-dice-1::before {
  content: ""; }

.bi-dice-2-fill::before {
  content: ""; }

.bi-dice-2::before {
  content: ""; }

.bi-dice-3-fill::before {
  content: ""; }

.bi-dice-3::before {
  content: ""; }

.bi-dice-4-fill::before {
  content: ""; }

.bi-dice-4::before {
  content: ""; }

.bi-dice-5-fill::before {
  content: ""; }

.bi-dice-5::before {
  content: ""; }

.bi-dice-6-fill::before {
  content: ""; }

.bi-dice-6::before {
  content: ""; }

.bi-disc-fill::before {
  content: ""; }

.bi-disc::before {
  content: ""; }

.bi-discord::before {
  content: ""; }

.bi-display-fill::before {
  content: ""; }

.bi-display::before {
  content: ""; }

.bi-distribute-horizontal::before {
  content: ""; }

.bi-distribute-vertical::before {
  content: ""; }

.bi-door-closed-fill::before {
  content: ""; }

.bi-door-closed::before {
  content: ""; }

.bi-door-open-fill::before {
  content: ""; }

.bi-door-open::before {
  content: ""; }

.bi-dot::before {
  content: ""; }

.bi-download::before {
  content: ""; }

.bi-droplet-fill::before {
  content: ""; }

.bi-droplet-half::before {
  content: ""; }

.bi-droplet::before {
  content: ""; }

.bi-earbuds::before {
  content: ""; }

.bi-easel-fill::before {
  content: ""; }

.bi-easel::before {
  content: ""; }

.bi-egg-fill::before {
  content: ""; }

.bi-egg-fried::before {
  content: ""; }

.bi-egg::before {
  content: ""; }

.bi-eject-fill::before {
  content: ""; }

.bi-eject::before {
  content: ""; }

.bi-emoji-angry-fill::before {
  content: ""; }

.bi-emoji-angry::before {
  content: ""; }

.bi-emoji-dizzy-fill::before {
  content: ""; }

.bi-emoji-dizzy::before {
  content: ""; }

.bi-emoji-expressionless-fill::before {
  content: ""; }

.bi-emoji-expressionless::before {
  content: ""; }

.bi-emoji-frown-fill::before {
  content: ""; }

.bi-emoji-frown::before {
  content: ""; }

.bi-emoji-heart-eyes-fill::before {
  content: ""; }

.bi-emoji-heart-eyes::before {
  content: ""; }

.bi-emoji-laughing-fill::before {
  content: ""; }

.bi-emoji-laughing::before {
  content: ""; }

.bi-emoji-neutral-fill::before {
  content: ""; }

.bi-emoji-neutral::before {
  content: ""; }

.bi-emoji-smile-fill::before {
  content: ""; }

.bi-emoji-smile-upside-down-fill::before {
  content: ""; }

.bi-emoji-smile-upside-down::before {
  content: ""; }

.bi-emoji-smile::before {
  content: ""; }

.bi-emoji-sunglasses-fill::before {
  content: ""; }

.bi-emoji-sunglasses::before {
  content: ""; }

.bi-emoji-wink-fill::before {
  content: ""; }

.bi-emoji-wink::before {
  content: ""; }

.bi-envelope-fill::before {
  content: ""; }

.bi-envelope-open-fill::before {
  content: ""; }

.bi-envelope-open::before {
  content: ""; }

.bi-envelope::before {
  content: ""; }

.bi-eraser-fill::before {
  content: ""; }

.bi-eraser::before {
  content: ""; }

.bi-exclamation-circle-fill::before {
  content: ""; }

.bi-exclamation-circle::before {
  content: ""; }

.bi-exclamation-diamond-fill::before {
  content: ""; }

.bi-exclamation-diamond::before {
  content: ""; }

.bi-exclamation-octagon-fill::before {
  content: ""; }

.bi-exclamation-octagon::before {
  content: ""; }

.bi-exclamation-square-fill::before {
  content: ""; }

.bi-exclamation-square::before {
  content: ""; }

.bi-exclamation-triangle-fill::before {
  content: ""; }

.bi-exclamation-triangle::before {
  content: ""; }

.bi-exclamation::before {
  content: ""; }

.bi-exclude::before {
  content: ""; }

.bi-eye-fill::before {
  content: ""; }

.bi-eye-slash-fill::before {
  content: ""; }

.bi-eye-slash::before {
  content: ""; }

.bi-eye::before {
  content: ""; }

.bi-eyedropper::before {
  content: ""; }

.bi-eyeglasses::before {
  content: ""; }

.bi-facebook::before {
  content: ""; }

.bi-file-arrow-down-fill::before {
  content: ""; }

.bi-file-arrow-down::before {
  content: ""; }

.bi-file-arrow-up-fill::before {
  content: ""; }

.bi-file-arrow-up::before {
  content: ""; }

.bi-file-bar-graph-fill::before {
  content: ""; }

.bi-file-bar-graph::before {
  content: ""; }

.bi-file-binary-fill::before {
  content: ""; }

.bi-file-binary::before {
  content: ""; }

.bi-file-break-fill::before {
  content: ""; }

.bi-file-break::before {
  content: ""; }

.bi-file-check-fill::before {
  content: ""; }

.bi-file-check::before {
  content: ""; }

.bi-file-code-fill::before {
  content: ""; }

.bi-file-code::before {
  content: ""; }

.bi-file-diff-fill::before {
  content: ""; }

.bi-file-diff::before {
  content: ""; }

.bi-file-earmark-arrow-down-fill::before {
  content: ""; }

.bi-file-earmark-arrow-down::before {
  content: ""; }

.bi-file-earmark-arrow-up-fill::before {
  content: ""; }

.bi-file-earmark-arrow-up::before {
  content: ""; }

.bi-file-earmark-bar-graph-fill::before {
  content: ""; }

.bi-file-earmark-bar-graph::before {
  content: ""; }

.bi-file-earmark-binary-fill::before {
  content: ""; }

.bi-file-earmark-binary::before {
  content: ""; }

.bi-file-earmark-break-fill::before {
  content: ""; }

.bi-file-earmark-break::before {
  content: ""; }

.bi-file-earmark-check-fill::before {
  content: ""; }

.bi-file-earmark-check::before {
  content: ""; }

.bi-file-earmark-code-fill::before {
  content: ""; }

.bi-file-earmark-code::before {
  content: ""; }

.bi-file-earmark-diff-fill::before {
  content: ""; }

.bi-file-earmark-diff::before {
  content: ""; }

.bi-file-earmark-easel-fill::before {
  content: ""; }

.bi-file-earmark-easel::before {
  content: ""; }

.bi-file-earmark-excel-fill::before {
  content: ""; }

.bi-file-earmark-excel::before {
  content: ""; }

.bi-file-earmark-fill::before {
  content: ""; }

.bi-file-earmark-font-fill::before {
  content: ""; }

.bi-file-earmark-font::before {
  content: ""; }

.bi-file-earmark-image-fill::before {
  content: ""; }

.bi-file-earmark-image::before {
  content: ""; }

.bi-file-earmark-lock-fill::before {
  content: ""; }

.bi-file-earmark-lock::before {
  content: ""; }

.bi-file-earmark-lock2-fill::before {
  content: ""; }

.bi-file-earmark-lock2::before {
  content: ""; }

.bi-file-earmark-medical-fill::before {
  content: ""; }

.bi-file-earmark-medical::before {
  content: ""; }

.bi-file-earmark-minus-fill::before {
  content: ""; }

.bi-file-earmark-minus::before {
  content: ""; }

.bi-file-earmark-music-fill::before {
  content: ""; }

.bi-file-earmark-music::before {
  content: ""; }

.bi-file-earmark-person-fill::before {
  content: ""; }

.bi-file-earmark-person::before {
  content: ""; }

.bi-file-earmark-play-fill::before {
  content: ""; }

.bi-file-earmark-play::before {
  content: ""; }

.bi-file-earmark-plus-fill::before {
  content: ""; }

.bi-file-earmark-plus::before {
  content: ""; }

.bi-file-earmark-post-fill::before {
  content: ""; }

.bi-file-earmark-post::before {
  content: ""; }

.bi-file-earmark-ppt-fill::before {
  content: ""; }

.bi-file-earmark-ppt::before {
  content: ""; }

.bi-file-earmark-richtext-fill::before {
  content: ""; }

.bi-file-earmark-richtext::before {
  content: ""; }

.bi-file-earmark-ruled-fill::before {
  content: ""; }

.bi-file-earmark-ruled::before {
  content: ""; }

.bi-file-earmark-slides-fill::before {
  content: ""; }

.bi-file-earmark-slides::before {
  content: ""; }

.bi-file-earmark-spreadsheet-fill::before {
  content: ""; }

.bi-file-earmark-spreadsheet::before {
  content: ""; }

.bi-file-earmark-text-fill::before {
  content: ""; }

.bi-file-earmark-text::before {
  content: ""; }

.bi-file-earmark-word-fill::before {
  content: ""; }

.bi-file-earmark-word::before {
  content: ""; }

.bi-file-earmark-x-fill::before {
  content: ""; }

.bi-file-earmark-x::before {
  content: ""; }

.bi-file-earmark-zip-fill::before {
  content: ""; }

.bi-file-earmark-zip::before {
  content: ""; }

.bi-file-earmark::before {
  content: ""; }

.bi-file-easel-fill::before {
  content: ""; }

.bi-file-easel::before {
  content: ""; }

.bi-file-excel-fill::before {
  content: ""; }

.bi-file-excel::before {
  content: ""; }

.bi-file-fill::before {
  content: ""; }

.bi-file-font-fill::before {
  content: ""; }

.bi-file-font::before {
  content: ""; }

.bi-file-image-fill::before {
  content: ""; }

.bi-file-image::before {
  content: ""; }

.bi-file-lock-fill::before {
  content: ""; }

.bi-file-lock::before {
  content: ""; }

.bi-file-lock2-fill::before {
  content: ""; }

.bi-file-lock2::before {
  content: ""; }

.bi-file-medical-fill::before {
  content: ""; }

.bi-file-medical::before {
  content: ""; }

.bi-file-minus-fill::before {
  content: ""; }

.bi-file-minus::before {
  content: ""; }

.bi-file-music-fill::before {
  content: ""; }

.bi-file-music::before {
  content: ""; }

.bi-file-person-fill::before {
  content: ""; }

.bi-file-person::before {
  content: ""; }

.bi-file-play-fill::before {
  content: ""; }

.bi-file-play::before {
  content: ""; }

.bi-file-plus-fill::before {
  content: ""; }

.bi-file-plus::before {
  content: ""; }

.bi-file-post-fill::before {
  content: ""; }

.bi-file-post::before {
  content: ""; }

.bi-file-ppt-fill::before {
  content: ""; }

.bi-file-ppt::before {
  content: ""; }

.bi-file-richtext-fill::before {
  content: ""; }

.bi-file-richtext::before {
  content: ""; }

.bi-file-ruled-fill::before {
  content: ""; }

.bi-file-ruled::before {
  content: ""; }

.bi-file-slides-fill::before {
  content: ""; }

.bi-file-slides::before {
  content: ""; }

.bi-file-spreadsheet-fill::before {
  content: ""; }

.bi-file-spreadsheet::before {
  content: ""; }

.bi-file-text-fill::before {
  content: ""; }

.bi-file-text::before {
  content: ""; }

.bi-file-word-fill::before {
  content: ""; }

.bi-file-word::before {
  content: ""; }

.bi-file-x-fill::before {
  content: ""; }

.bi-file-x::before {
  content: ""; }

.bi-file-zip-fill::before {
  content: ""; }

.bi-file-zip::before {
  content: ""; }

.bi-file::before {
  content: ""; }

.bi-files-alt::before {
  content: ""; }

.bi-files::before {
  content: ""; }

.bi-film::before {
  content: ""; }

.bi-filter-circle-fill::before {
  content: ""; }

.bi-filter-circle::before {
  content: ""; }

.bi-filter-left::before {
  content: ""; }

.bi-filter-right::before {
  content: ""; }

.bi-filter-square-fill::before {
  content: ""; }

.bi-filter-square::before {
  content: ""; }

.bi-filter::before {
  content: ""; }

.bi-flag-fill::before {
  content: ""; }

.bi-flag::before {
  content: ""; }

.bi-flower1::before {
  content: ""; }

.bi-flower2::before {
  content: ""; }

.bi-flower3::before {
  content: ""; }

.bi-folder-check::before {
  content: ""; }

.bi-folder-fill::before {
  content: ""; }

.bi-folder-minus::before {
  content: ""; }

.bi-folder-plus::before {
  content: ""; }

.bi-folder-symlink-fill::before {
  content: ""; }

.bi-folder-symlink::before {
  content: ""; }

.bi-folder-x::before {
  content: ""; }

.bi-folder::before {
  content: ""; }

.bi-folder2-open::before {
  content: ""; }

.bi-folder2::before {
  content: ""; }

.bi-fonts::before {
  content: ""; }

.bi-forward-fill::before {
  content: ""; }

.bi-forward::before {
  content: ""; }

.bi-front::before {
  content: ""; }

.bi-fullscreen-exit::before {
  content: ""; }

.bi-fullscreen::before {
  content: ""; }

.bi-funnel-fill::before {
  content: ""; }

.bi-funnel::before {
  content: ""; }

.bi-gear-fill::before {
  content: ""; }

.bi-gear-wide-connected::before {
  content: ""; }

.bi-gear-wide::before {
  content: ""; }

.bi-gear::before {
  content: ""; }

.bi-gem::before {
  content: ""; }

.bi-geo-alt-fill::before {
  content: ""; }

.bi-geo-alt::before {
  content: ""; }

.bi-geo-fill::before {
  content: ""; }

.bi-geo::before {
  content: ""; }

.bi-gift-fill::before {
  content: ""; }

.bi-gift::before {
  content: ""; }

.bi-github::before {
  content: ""; }

.bi-globe::before {
  content: ""; }

.bi-globe2::before {
  content: ""; }

.bi-google::before {
  content: ""; }

.bi-graph-down::before {
  content: ""; }

.bi-graph-up::before {
  content: ""; }

.bi-grid-1x2-fill::before {
  content: ""; }

.bi-grid-1x2::before {
  content: ""; }

.bi-grid-3x2-gap-fill::before {
  content: ""; }

.bi-grid-3x2-gap::before {
  content: ""; }

.bi-grid-3x2::before {
  content: ""; }

.bi-grid-3x3-gap-fill::before {
  content: ""; }

.bi-grid-3x3-gap::before {
  content: ""; }

.bi-grid-3x3::before {
  content: ""; }

.bi-grid-fill::before {
  content: ""; }

.bi-grid::before {
  content: ""; }

.bi-grip-horizontal::before {
  content: ""; }

.bi-grip-vertical::before {
  content: ""; }

.bi-hammer::before {
  content: ""; }

.bi-hand-index-fill::before {
  content: ""; }

.bi-hand-index-thumb-fill::before {
  content: ""; }

.bi-hand-index-thumb::before {
  content: ""; }

.bi-hand-index::before {
  content: ""; }

.bi-hand-thumbs-down-fill::before {
  content: ""; }

.bi-hand-thumbs-down::before {
  content: ""; }

.bi-hand-thumbs-up-fill::before {
  content: ""; }

.bi-hand-thumbs-up::before {
  content: ""; }

.bi-handbag-fill::before {
  content: ""; }

.bi-handbag::before {
  content: ""; }

.bi-hash::before {
  content: ""; }

.bi-hdd-fill::before {
  content: ""; }

.bi-hdd-network-fill::before {
  content: ""; }

.bi-hdd-network::before {
  content: ""; }

.bi-hdd-rack-fill::before {
  content: ""; }

.bi-hdd-rack::before {
  content: ""; }

.bi-hdd-stack-fill::before {
  content: ""; }

.bi-hdd-stack::before {
  content: ""; }

.bi-hdd::before {
  content: ""; }

.bi-headphones::before {
  content: ""; }

.bi-headset::before {
  content: ""; }

.bi-heart-fill::before {
  content: ""; }

.bi-heart-half::before {
  content: ""; }

.bi-heart::before {
  content: ""; }

.bi-heptagon-fill::before {
  content: ""; }

.bi-heptagon-half::before {
  content: ""; }

.bi-heptagon::before {
  content: ""; }

.bi-hexagon-fill::before {
  content: ""; }

.bi-hexagon-half::before {
  content: ""; }

.bi-hexagon::before {
  content: ""; }

.bi-hourglass-bottom::before {
  content: ""; }

.bi-hourglass-split::before {
  content: ""; }

.bi-hourglass-top::before {
  content: ""; }

.bi-hourglass::before {
  content: ""; }

.bi-house-door-fill::before {
  content: ""; }

.bi-house-door::before {
  content: ""; }

.bi-house-fill::before {
  content: ""; }

.bi-house::before {
  content: ""; }

.bi-hr::before {
  content: ""; }

.bi-hurricane::before {
  content: ""; }

.bi-image-alt::before {
  content: ""; }

.bi-image-fill::before {
  content: ""; }

.bi-image::before {
  content: ""; }

.bi-images::before {
  content: ""; }

.bi-inbox-fill::before {
  content: ""; }

.bi-inbox::before {
  content: ""; }

.bi-inboxes-fill::before {
  content: ""; }

.bi-inboxes::before {
  content: ""; }

.bi-info-circle-fill::before {
  content: ""; }

.bi-info-circle::before {
  content: ""; }

.bi-info-square-fill::before {
  content: ""; }

.bi-info-square::before {
  content: ""; }

.bi-info::before {
  content: ""; }

.bi-input-cursor-text::before {
  content: ""; }

.bi-input-cursor::before {
  content: ""; }

.bi-instagram::before {
  content: ""; }

.bi-intersect::before {
  content: ""; }

.bi-journal-album::before {
  content: ""; }

.bi-journal-arrow-down::before {
  content: ""; }

.bi-journal-arrow-up::before {
  content: ""; }

.bi-journal-bookmark-fill::before {
  content: ""; }

.bi-journal-bookmark::before {
  content: ""; }

.bi-journal-check::before {
  content: ""; }

.bi-journal-code::before {
  content: ""; }

.bi-journal-medical::before {
  content: ""; }

.bi-journal-minus::before {
  content: ""; }

.bi-journal-plus::before {
  content: ""; }

.bi-journal-richtext::before {
  content: ""; }

.bi-journal-text::before {
  content: ""; }

.bi-journal-x::before {
  content: ""; }

.bi-journal::before {
  content: ""; }

.bi-journals::before {
  content: ""; }

.bi-joystick::before {
  content: ""; }

.bi-justify-left::before {
  content: ""; }

.bi-justify-right::before {
  content: ""; }

.bi-justify::before {
  content: ""; }

.bi-kanban-fill::before {
  content: ""; }

.bi-kanban::before {
  content: ""; }

.bi-key-fill::before {
  content: ""; }

.bi-key::before {
  content: ""; }

.bi-keyboard-fill::before {
  content: ""; }

.bi-keyboard::before {
  content: ""; }

.bi-ladder::before {
  content: ""; }

.bi-lamp-fill::before {
  content: ""; }

.bi-lamp::before {
  content: ""; }

.bi-laptop-fill::before {
  content: ""; }

.bi-laptop::before {
  content: ""; }

.bi-layer-backward::before {
  content: ""; }

.bi-layer-forward::before {
  content: ""; }

.bi-layers-fill::before {
  content: ""; }

.bi-layers-half::before {
  content: ""; }

.bi-layers::before {
  content: ""; }

.bi-layout-sidebar-inset-reverse::before {
  content: ""; }

.bi-layout-sidebar-inset::before {
  content: ""; }

.bi-layout-sidebar-reverse::before {
  content: ""; }

.bi-layout-sidebar::before {
  content: ""; }

.bi-layout-split::before {
  content: ""; }

.bi-layout-text-sidebar-reverse::before {
  content: ""; }

.bi-layout-text-sidebar::before {
  content: ""; }

.bi-layout-text-window-reverse::before {
  content: ""; }

.bi-layout-text-window::before {
  content: ""; }

.bi-layout-three-columns::before {
  content: ""; }

.bi-layout-wtf::before {
  content: ""; }

.bi-life-preserver::before {
  content: ""; }

.bi-lightbulb-fill::before {
  content: ""; }

.bi-lightbulb-off-fill::before {
  content: ""; }

.bi-lightbulb-off::before {
  content: ""; }

.bi-lightbulb::before {
  content: ""; }

.bi-lightning-charge-fill::before {
  content: ""; }

.bi-lightning-charge::before {
  content: ""; }

.bi-lightning-fill::before {
  content: ""; }

.bi-lightning::before {
  content: ""; }

.bi-link-45deg::before {
  content: ""; }

.bi-link::before {
  content: ""; }

.bi-linkedin::before {
  content: ""; }

.bi-list-check::before {
  content: ""; }

.bi-list-nested::before {
  content: ""; }

.bi-list-ol::before {
  content: ""; }

.bi-list-stars::before {
  content: ""; }

.bi-list-task::before {
  content: ""; }

.bi-list-ul::before {
  content: ""; }

.bi-list::before {
  content: ""; }

.bi-lock-fill::before {
  content: ""; }

.bi-lock::before {
  content: ""; }

.bi-mailbox::before {
  content: ""; }

.bi-mailbox2::before {
  content: ""; }

.bi-map-fill::before {
  content: ""; }

.bi-map::before {
  content: ""; }

.bi-markdown-fill::before {
  content: ""; }

.bi-markdown::before {
  content: ""; }

.bi-mask::before {
  content: ""; }

.bi-megaphone-fill::before {
  content: ""; }

.bi-megaphone::before {
  content: ""; }

.bi-menu-app-fill::before {
  content: ""; }

.bi-menu-app::before {
  content: ""; }

.bi-menu-button-fill::before {
  content: ""; }

.bi-menu-button-wide-fill::before {
  content: ""; }

.bi-menu-button-wide::before {
  content: ""; }

.bi-menu-button::before {
  content: ""; }

.bi-menu-down::before {
  content: ""; }

.bi-menu-up::before {
  content: ""; }

.bi-mic-fill::before {
  content: ""; }

.bi-mic-mute-fill::before {
  content: ""; }

.bi-mic-mute::before {
  content: ""; }

.bi-mic::before {
  content: ""; }

.bi-minecart-loaded::before {
  content: ""; }

.bi-minecart::before {
  content: ""; }

.bi-moisture::before {
  content: ""; }

.bi-moon-fill::before {
  content: ""; }

.bi-moon-stars-fill::before {
  content: ""; }

.bi-moon-stars::before {
  content: ""; }

.bi-moon::before {
  content: ""; }

.bi-mouse-fill::before {
  content: ""; }

.bi-mouse::before {
  content: ""; }

.bi-mouse2-fill::before {
  content: ""; }

.bi-mouse2::before {
  content: ""; }

.bi-mouse3-fill::before {
  content: ""; }

.bi-mouse3::before {
  content: ""; }

.bi-music-note-beamed::before {
  content: ""; }

.bi-music-note-list::before {
  content: ""; }

.bi-music-note::before {
  content: ""; }

.bi-music-player-fill::before {
  content: ""; }

.bi-music-player::before {
  content: ""; }

.bi-newspaper::before {
  content: ""; }

.bi-node-minus-fill::before {
  content: ""; }

.bi-node-minus::before {
  content: ""; }

.bi-node-plus-fill::before {
  content: ""; }

.bi-node-plus::before {
  content: ""; }

.bi-nut-fill::before {
  content: ""; }

.bi-nut::before {
  content: ""; }

.bi-octagon-fill::before {
  content: ""; }

.bi-octagon-half::before {
  content: ""; }

.bi-octagon::before {
  content: ""; }

.bi-option::before {
  content: ""; }

.bi-outlet::before {
  content: ""; }

.bi-paint-bucket::before {
  content: ""; }

.bi-palette-fill::before {
  content: ""; }

.bi-palette::before {
  content: ""; }

.bi-palette2::before {
  content: ""; }

.bi-paperclip::before {
  content: ""; }

.bi-paragraph::before {
  content: ""; }

.bi-patch-check-fill::before {
  content: ""; }

.bi-patch-check::before {
  content: ""; }

.bi-patch-exclamation-fill::before {
  content: ""; }

.bi-patch-exclamation::before {
  content: ""; }

.bi-patch-minus-fill::before {
  content: ""; }

.bi-patch-minus::before {
  content: ""; }

.bi-patch-plus-fill::before {
  content: ""; }

.bi-patch-plus::before {
  content: ""; }

.bi-patch-question-fill::before {
  content: ""; }

.bi-patch-question::before {
  content: ""; }

.bi-pause-btn-fill::before {
  content: ""; }

.bi-pause-btn::before {
  content: ""; }

.bi-pause-circle-fill::before {
  content: ""; }

.bi-pause-circle::before {
  content: ""; }

.bi-pause-fill::before {
  content: ""; }

.bi-pause::before {
  content: ""; }

.bi-peace-fill::before {
  content: ""; }

.bi-peace::before {
  content: ""; }

.bi-pen-fill::before {
  content: ""; }

.bi-pen::before {
  content: ""; }

.bi-pencil-fill::before {
  content: ""; }

.bi-pencil-square::before {
  content: ""; }

.bi-pencil::before {
  content: ""; }

.bi-pentagon-fill::before {
  content: ""; }

.bi-pentagon-half::before {
  content: ""; }

.bi-pentagon::before {
  content: ""; }

.bi-people-fill::before {
  content: ""; }

.bi-people::before {
  content: ""; }

.bi-percent::before {
  content: ""; }

.bi-person-badge-fill::before {
  content: ""; }

.bi-person-badge::before {
  content: ""; }

.bi-person-bounding-box::before {
  content: ""; }

.bi-person-check-fill::before {
  content: ""; }

.bi-person-check::before {
  content: ""; }

.bi-person-circle::before {
  content: ""; }

.bi-person-dash-fill::before {
  content: ""; }

.bi-person-dash::before {
  content: ""; }

.bi-person-fill::before {
  content: ""; }

.bi-person-lines-fill::before {
  content: ""; }

.bi-person-plus-fill::before {
  content: ""; }

.bi-person-plus::before {
  content: ""; }

.bi-person-square::before {
  content: ""; }

.bi-person-x-fill::before {
  content: ""; }

.bi-person-x::before {
  content: ""; }

.bi-person::before {
  content: ""; }

.bi-phone-fill::before {
  content: ""; }

.bi-phone-landscape-fill::before {
  content: ""; }

.bi-phone-landscape::before {
  content: ""; }

.bi-phone-vibrate-fill::before {
  content: ""; }

.bi-phone-vibrate::before {
  content: ""; }

.bi-phone::before {
  content: ""; }

.bi-pie-chart-fill::before {
  content: ""; }

.bi-pie-chart::before {
  content: ""; }

.bi-pin-angle-fill::before {
  content: ""; }

.bi-pin-angle::before {
  content: ""; }

.bi-pin-fill::before {
  content: ""; }

.bi-pin::before {
  content: ""; }

.bi-pip-fill::before {
  content: ""; }

.bi-pip::before {
  content: ""; }

.bi-play-btn-fill::before {
  content: ""; }

.bi-play-btn::before {
  content: ""; }

.bi-play-circle-fill::before {
  content: ""; }

.bi-play-circle::before {
  content: ""; }

.bi-play-fill::before {
  content: ""; }

.bi-play::before {
  content: ""; }

.bi-plug-fill::before {
  content: ""; }

.bi-plug::before {
  content: ""; }

.bi-plus-circle-dotted::before {
  content: ""; }

.bi-plus-circle-fill::before {
  content: ""; }

.bi-plus-circle::before {
  content: ""; }

.bi-plus-square-dotted::before {
  content: ""; }

.bi-plus-square-fill::before {
  content: ""; }

.bi-plus-square::before {
  content: ""; }

.bi-plus::before {
  content: ""; }

.bi-power::before {
  content: ""; }

.bi-printer-fill::before {
  content: ""; }

.bi-printer::before {
  content: ""; }

.bi-puzzle-fill::before {
  content: ""; }

.bi-puzzle::before {
  content: ""; }

.bi-question-circle-fill::before {
  content: ""; }

.bi-question-circle::before {
  content: ""; }

.bi-question-diamond-fill::before {
  content: ""; }

.bi-question-diamond::before {
  content: ""; }

.bi-question-octagon-fill::before {
  content: ""; }

.bi-question-octagon::before {
  content: ""; }

.bi-question-square-fill::before {
  content: ""; }

.bi-question-square::before {
  content: ""; }

.bi-question::before {
  content: ""; }

.bi-rainbow::before {
  content: ""; }

.bi-receipt-cutoff::before {
  content: ""; }

.bi-receipt::before {
  content: ""; }

.bi-reception-0::before {
  content: ""; }

.bi-reception-1::before {
  content: ""; }

.bi-reception-2::before {
  content: ""; }

.bi-reception-3::before {
  content: ""; }

.bi-reception-4::before {
  content: ""; }

.bi-record-btn-fill::before {
  content: ""; }

.bi-record-btn::before {
  content: ""; }

.bi-record-circle-fill::before {
  content: ""; }

.bi-record-circle::before {
  content: ""; }

.bi-record-fill::before {
  content: ""; }

.bi-record::before {
  content: ""; }

.bi-record2-fill::before {
  content: ""; }

.bi-record2::before {
  content: ""; }

.bi-reply-all-fill::before {
  content: ""; }

.bi-reply-all::before {
  content: ""; }

.bi-reply-fill::before {
  content: ""; }

.bi-reply::before {
  content: ""; }

.bi-rss-fill::before {
  content: ""; }

.bi-rss::before {
  content: ""; }

.bi-rulers::before {
  content: ""; }

.bi-save-fill::before {
  content: ""; }

.bi-save::before {
  content: ""; }

.bi-save2-fill::before {
  content: ""; }

.bi-save2::before {
  content: ""; }

.bi-scissors::before {
  content: ""; }

.bi-screwdriver::before {
  content: ""; }

.bi-search::before {
  content: ""; }

.bi-segmented-nav::before {
  content: ""; }

.bi-server::before {
  content: ""; }

.bi-share-fill::before {
  content: ""; }

.bi-share::before {
  content: ""; }

.bi-shield-check::before {
  content: ""; }

.bi-shield-exclamation::before {
  content: ""; }

.bi-shield-fill-check::before {
  content: ""; }

.bi-shield-fill-exclamation::before {
  content: ""; }

.bi-shield-fill-minus::before {
  content: ""; }

.bi-shield-fill-plus::before {
  content: ""; }

.bi-shield-fill-x::before {
  content: ""; }

.bi-shield-fill::before {
  content: ""; }

.bi-shield-lock-fill::before {
  content: ""; }

.bi-shield-lock::before {
  content: ""; }

.bi-shield-minus::before {
  content: ""; }

.bi-shield-plus::before {
  content: ""; }

.bi-shield-shaded::before {
  content: ""; }

.bi-shield-slash-fill::before {
  content: ""; }

.bi-shield-slash::before {
  content: ""; }

.bi-shield-x::before {
  content: ""; }

.bi-shield::before {
  content: ""; }

.bi-shift-fill::before {
  content: ""; }

.bi-shift::before {
  content: ""; }

.bi-shop-window::before {
  content: ""; }

.bi-shop::before {
  content: ""; }

.bi-shuffle::before {
  content: ""; }

.bi-signpost-2-fill::before {
  content: ""; }

.bi-signpost-2::before {
  content: ""; }

.bi-signpost-fill::before {
  content: ""; }

.bi-signpost-split-fill::before {
  content: ""; }

.bi-signpost-split::before {
  content: ""; }

.bi-signpost::before {
  content: ""; }

.bi-sim-fill::before {
  content: ""; }

.bi-sim::before {
  content: ""; }

.bi-skip-backward-btn-fill::before {
  content: ""; }

.bi-skip-backward-btn::before {
  content: ""; }

.bi-skip-backward-circle-fill::before {
  content: ""; }

.bi-skip-backward-circle::before {
  content: ""; }

.bi-skip-backward-fill::before {
  content: ""; }

.bi-skip-backward::before {
  content: ""; }

.bi-skip-end-btn-fill::before {
  content: ""; }

.bi-skip-end-btn::before {
  content: ""; }

.bi-skip-end-circle-fill::before {
  content: ""; }

.bi-skip-end-circle::before {
  content: ""; }

.bi-skip-end-fill::before {
  content: ""; }

.bi-skip-end::before {
  content: ""; }

.bi-skip-forward-btn-fill::before {
  content: ""; }

.bi-skip-forward-btn::before {
  content: ""; }

.bi-skip-forward-circle-fill::before {
  content: ""; }

.bi-skip-forward-circle::before {
  content: ""; }

.bi-skip-forward-fill::before {
  content: ""; }

.bi-skip-forward::before {
  content: ""; }

.bi-skip-start-btn-fill::before {
  content: ""; }

.bi-skip-start-btn::before {
  content: ""; }

.bi-skip-start-circle-fill::before {
  content: ""; }

.bi-skip-start-circle::before {
  content: ""; }

.bi-skip-start-fill::before {
  content: ""; }

.bi-skip-start::before {
  content: ""; }

.bi-slack::before {
  content: ""; }

.bi-slash-circle-fill::before {
  content: ""; }

.bi-slash-circle::before {
  content: ""; }

.bi-slash-square-fill::before {
  content: ""; }

.bi-slash-square::before {
  content: ""; }

.bi-slash::before {
  content: ""; }

.bi-sliders::before {
  content: ""; }

.bi-smartwatch::before {
  content: ""; }

.bi-snow::before {
  content: ""; }

.bi-snow2::before {
  content: ""; }

.bi-snow3::before {
  content: ""; }

.bi-sort-alpha-down-alt::before {
  content: ""; }

.bi-sort-alpha-down::before {
  content: ""; }

.bi-sort-alpha-up-alt::before {
  content: ""; }

.bi-sort-alpha-up::before {
  content: ""; }

.bi-sort-down-alt::before {
  content: ""; }

.bi-sort-down::before {
  content: ""; }

.bi-sort-numeric-down-alt::before {
  content: ""; }

.bi-sort-numeric-down::before {
  content: ""; }

.bi-sort-numeric-up-alt::before {
  content: ""; }

.bi-sort-numeric-up::before {
  content: ""; }

.bi-sort-up-alt::before {
  content: ""; }

.bi-sort-up::before {
  content: ""; }

.bi-soundwave::before {
  content: ""; }

.bi-speaker-fill::before {
  content: ""; }

.bi-speaker::before {
  content: ""; }

.bi-speedometer::before {
  content: ""; }

.bi-speedometer2::before {
  content: ""; }

.bi-spellcheck::before {
  content: ""; }

.bi-square-fill::before {
  content: ""; }

.bi-square-half::before {
  content: ""; }

.bi-square::before {
  content: ""; }

.bi-stack::before {
  content: ""; }

.bi-star-fill::before {
  content: ""; }

.bi-star-half::before {
  content: ""; }

.bi-star::before {
  content: ""; }

.bi-stars::before {
  content: ""; }

.bi-stickies-fill::before {
  content: ""; }

.bi-stickies::before {
  content: ""; }

.bi-sticky-fill::before {
  content: ""; }

.bi-sticky::before {
  content: ""; }

.bi-stop-btn-fill::before {
  content: ""; }

.bi-stop-btn::before {
  content: ""; }

.bi-stop-circle-fill::before {
  content: ""; }

.bi-stop-circle::before {
  content: ""; }

.bi-stop-fill::before {
  content: ""; }

.bi-stop::before {
  content: ""; }

.bi-stoplights-fill::before {
  content: ""; }

.bi-stoplights::before {
  content: ""; }

.bi-stopwatch-fill::before {
  content: ""; }

.bi-stopwatch::before {
  content: ""; }

.bi-subtract::before {
  content: ""; }

.bi-suit-club-fill::before {
  content: ""; }

.bi-suit-club::before {
  content: ""; }

.bi-suit-diamond-fill::before {
  content: ""; }

.bi-suit-diamond::before {
  content: ""; }

.bi-suit-heart-fill::before {
  content: ""; }

.bi-suit-heart::before {
  content: ""; }

.bi-suit-spade-fill::before {
  content: ""; }

.bi-suit-spade::before {
  content: ""; }

.bi-sun-fill::before {
  content: ""; }

.bi-sun::before {
  content: ""; }

.bi-sunglasses::before {
  content: ""; }

.bi-sunrise-fill::before {
  content: ""; }

.bi-sunrise::before {
  content: ""; }

.bi-sunset-fill::before {
  content: ""; }

.bi-sunset::before {
  content: ""; }

.bi-symmetry-horizontal::before {
  content: ""; }

.bi-symmetry-vertical::before {
  content: ""; }

.bi-table::before {
  content: ""; }

.bi-tablet-fill::before {
  content: ""; }

.bi-tablet-landscape-fill::before {
  content: ""; }

.bi-tablet-landscape::before {
  content: ""; }

.bi-tablet::before {
  content: ""; }

.bi-tag-fill::before {
  content: ""; }

.bi-tag::before {
  content: ""; }

.bi-tags-fill::before {
  content: ""; }

.bi-tags::before {
  content: ""; }

.bi-telegram::before {
  content: ""; }

.bi-telephone-fill::before {
  content: ""; }

.bi-telephone-forward-fill::before {
  content: ""; }

.bi-telephone-forward::before {
  content: ""; }

.bi-telephone-inbound-fill::before {
  content: ""; }

.bi-telephone-inbound::before {
  content: ""; }

.bi-telephone-minus-fill::before {
  content: ""; }

.bi-telephone-minus::before {
  content: ""; }

.bi-telephone-outbound-fill::before {
  content: ""; }

.bi-telephone-outbound::before {
  content: ""; }

.bi-telephone-plus-fill::before {
  content: ""; }

.bi-telephone-plus::before {
  content: ""; }

.bi-telephone-x-fill::before {
  content: ""; }

.bi-telephone-x::before {
  content: ""; }

.bi-telephone::before {
  content: ""; }

.bi-terminal-fill::before {
  content: ""; }

.bi-terminal::before {
  content: ""; }

.bi-text-center::before {
  content: ""; }

.bi-text-indent-left::before {
  content: ""; }

.bi-text-indent-right::before {
  content: ""; }

.bi-text-left::before {
  content: ""; }

.bi-text-paragraph::before {
  content: ""; }

.bi-text-right::before {
  content: ""; }

.bi-textarea-resize::before {
  content: ""; }

.bi-textarea-t::before {
  content: ""; }

.bi-textarea::before {
  content: ""; }

.bi-thermometer-half::before {
  content: ""; }

.bi-thermometer-high::before {
  content: ""; }

.bi-thermometer-low::before {
  content: ""; }

.bi-thermometer-snow::before {
  content: ""; }

.bi-thermometer-sun::before {
  content: ""; }

.bi-thermometer::before {
  content: ""; }

.bi-three-dots-vertical::before {
  content: ""; }

.bi-three-dots::before {
  content: ""; }

.bi-toggle-off::before {
  content: ""; }

.bi-toggle-on::before {
  content: ""; }

.bi-toggle2-off::before {
  content: ""; }

.bi-toggle2-on::before {
  content: ""; }

.bi-toggles::before {
  content: ""; }

.bi-toggles2::before {
  content: ""; }

.bi-tools::before {
  content: ""; }

.bi-tornado::before {
  content: ""; }

.bi-trash-fill::before {
  content: ""; }

.bi-trash::before {
  content: ""; }

.bi-trash2-fill::before {
  content: ""; }

.bi-trash2::before {
  content: ""; }

.bi-tree-fill::before {
  content: ""; }

.bi-tree::before {
  content: ""; }

.bi-triangle-fill::before {
  content: ""; }

.bi-triangle-half::before {
  content: ""; }

.bi-triangle::before {
  content: ""; }

.bi-trophy-fill::before {
  content: ""; }

.bi-trophy::before {
  content: ""; }

.bi-tropical-storm::before {
  content: ""; }

.bi-truck-flatbed::before {
  content: ""; }

.bi-truck::before {
  content: ""; }

.bi-tsunami::before {
  content: ""; }

.bi-tv-fill::before {
  content: ""; }

.bi-tv::before {
  content: ""; }

.bi-twitch::before {
  content: ""; }

.bi-twitter::before {
  content: ""; }

.bi-type-bold::before {
  content: ""; }

.bi-type-h1::before {
  content: ""; }

.bi-type-h2::before {
  content: ""; }

.bi-type-h3::before {
  content: ""; }

.bi-type-italic::before {
  content: ""; }

.bi-type-strikethrough::before {
  content: ""; }

.bi-type-underline::before {
  content: ""; }

.bi-type::before {
  content: ""; }

.bi-ui-checks-grid::before {
  content: ""; }

.bi-ui-checks::before {
  content: ""; }

.bi-ui-radios-grid::before {
  content: ""; }

.bi-ui-radios::before {
  content: ""; }

.bi-umbrella-fill::before {
  content: ""; }

.bi-umbrella::before {
  content: ""; }

.bi-union::before {
  content: ""; }

.bi-unlock-fill::before {
  content: ""; }

.bi-unlock::before {
  content: ""; }

.bi-upc-scan::before {
  content: ""; }

.bi-upc::before {
  content: ""; }

.bi-upload::before {
  content: ""; }

.bi-vector-pen::before {
  content: ""; }

.bi-view-list::before {
  content: ""; }

.bi-view-stacked::before {
  content: ""; }

.bi-vinyl-fill::before {
  content: ""; }

.bi-vinyl::before {
  content: ""; }

.bi-voicemail::before {
  content: ""; }

.bi-volume-down-fill::before {
  content: ""; }

.bi-volume-down::before {
  content: ""; }

.bi-volume-mute-fill::before {
  content: ""; }

.bi-volume-mute::before {
  content: ""; }

.bi-volume-off-fill::before {
  content: ""; }

.bi-volume-off::before {
  content: ""; }

.bi-volume-up-fill::before {
  content: ""; }

.bi-volume-up::before {
  content: ""; }

.bi-vr::before {
  content: ""; }

.bi-wallet-fill::before {
  content: ""; }

.bi-wallet::before {
  content: ""; }

.bi-wallet2::before {
  content: ""; }

.bi-watch::before {
  content: ""; }

.bi-water::before {
  content: ""; }

.bi-whatsapp::before {
  content: ""; }

.bi-wifi-1::before {
  content: ""; }

.bi-wifi-2::before {
  content: ""; }

.bi-wifi-off::before {
  content: ""; }

.bi-wifi::before {
  content: ""; }

.bi-wind::before {
  content: ""; }

.bi-window-dock::before {
  content: ""; }

.bi-window-sidebar::before {
  content: ""; }

.bi-window::before {
  content: ""; }

.bi-wrench::before {
  content: ""; }

.bi-x-circle-fill::before {
  content: ""; }

.bi-x-circle::before {
  content: ""; }

.bi-x-diamond-fill::before {
  content: ""; }

.bi-x-diamond::before {
  content: ""; }

.bi-x-octagon-fill::before {
  content: ""; }

.bi-x-octagon::before {
  content: ""; }

.bi-x-square-fill::before {
  content: ""; }

.bi-x-square::before {
  content: ""; }

.bi-x::before {
  content: ""; }

.bi-youtube::before {
  content: ""; }

.bi-zoom-in::before {
  content: ""; }

.bi-zoom-out::before {
  content: ""; }

.bi-bank::before {
  content: ""; }

.bi-bank2::before {
  content: ""; }

.bi-bell-slash-fill::before {
  content: ""; }

.bi-bell-slash::before {
  content: ""; }

.bi-cash-coin::before {
  content: ""; }

.bi-check-lg::before {
  content: ""; }

.bi-coin::before {
  content: ""; }

.bi-currency-bitcoin::before {
  content: ""; }

.bi-currency-dollar::before {
  content: ""; }

.bi-currency-euro::before {
  content: ""; }

.bi-currency-exchange::before {
  content: ""; }

.bi-currency-pound::before {
  content: ""; }

.bi-currency-yen::before {
  content: ""; }

.bi-dash-lg::before {
  content: ""; }

.bi-exclamation-lg::before {
  content: ""; }

.bi-file-earmark-pdf-fill::before {
  content: ""; }

.bi-file-earmark-pdf::before {
  content: ""; }

.bi-file-pdf-fill::before {
  content: ""; }

.bi-file-pdf::before {
  content: ""; }

.bi-gender-ambiguous::before {
  content: ""; }

.bi-gender-female::before {
  content: ""; }

.bi-gender-male::before {
  content: ""; }

.bi-gender-trans::before {
  content: ""; }

.bi-headset-vr::before {
  content: ""; }

.bi-info-lg::before {
  content: ""; }

.bi-mastodon::before {
  content: ""; }

.bi-messenger::before {
  content: ""; }

.bi-piggy-bank-fill::before {
  content: ""; }

.bi-piggy-bank::before {
  content: ""; }

.bi-pin-map-fill::before {
  content: ""; }

.bi-pin-map::before {
  content: ""; }

.bi-plus-lg::before {
  content: ""; }

.bi-question-lg::before {
  content: ""; }

.bi-recycle::before {
  content: ""; }

.bi-reddit::before {
  content: ""; }

.bi-safe-fill::before {
  content: ""; }

.bi-safe2-fill::before {
  content: ""; }

.bi-safe2::before {
  content: ""; }

.bi-sd-card-fill::before {
  content: ""; }

.bi-sd-card::before {
  content: ""; }

.bi-skype::before {
  content: ""; }

.bi-slash-lg::before {
  content: ""; }

.bi-translate::before {
  content: ""; }

.bi-x-lg::before {
  content: ""; }

.bi-safe::before {
  content: ""; }

.bi-apple::before {
  content: ""; }

.bi-microsoft::before {
  content: ""; }

.bi-windows::before {
  content: ""; }

.bi-behance::before {
  content: ""; }

.bi-dribbble::before {
  content: ""; }

.bi-line::before {
  content: ""; }

.bi-medium::before {
  content: ""; }

.bi-paypal::before {
  content: ""; }

.bi-pinterest::before {
  content: ""; }

.bi-signal::before {
  content: ""; }

.bi-snapchat::before {
  content: ""; }

.bi-spotify::before {
  content: ""; }

.bi-stack-overflow::before {
  content: ""; }

.bi-strava::before {
  content: ""; }

.bi-wordpress::before {
  content: ""; }

.bi-vimeo::before {
  content: ""; }

.bi-activity::before {
  content: ""; }

.bi-easel2-fill::before {
  content: ""; }

.bi-easel2::before {
  content: ""; }

.bi-easel3-fill::before {
  content: ""; }

.bi-easel3::before {
  content: ""; }

.bi-fan::before {
  content: ""; }

.bi-fingerprint::before {
  content: ""; }

.bi-graph-down-arrow::before {
  content: ""; }

.bi-graph-up-arrow::before {
  content: ""; }

.bi-hypnotize::before {
  content: ""; }

.bi-magic::before {
  content: ""; }

.bi-person-rolodex::before {
  content: ""; }

.bi-person-video::before {
  content: ""; }

.bi-person-video2::before {
  content: ""; }

.bi-person-video3::before {
  content: ""; }

.bi-person-workspace::before {
  content: ""; }

.bi-radioactive::before {
  content: ""; }

.bi-webcam-fill::before {
  content: ""; }

.bi-webcam::before {
  content: ""; }

.bi-yin-yang::before {
  content: ""; }

.bi-bandaid-fill::before {
  content: ""; }

.bi-bandaid::before {
  content: ""; }

.bi-bluetooth::before {
  content: ""; }

.bi-body-text::before {
  content: ""; }

.bi-boombox::before {
  content: ""; }

.bi-boxes::before {
  content: ""; }

.bi-dpad-fill::before {
  content: ""; }

.bi-dpad::before {
  content: ""; }

.bi-ear-fill::before {
  content: ""; }

.bi-ear::before {
  content: ""; }

.bi-envelope-check-fill::before {
  content: ""; }

.bi-envelope-check::before {
  content: ""; }

.bi-envelope-dash-fill::before {
  content: ""; }

.bi-envelope-dash::before {
  content: ""; }

.bi-envelope-exclamation-fill::before {
  content: ""; }

.bi-envelope-exclamation::before {
  content: ""; }

.bi-envelope-plus-fill::before {
  content: ""; }

.bi-envelope-plus::before {
  content: ""; }

.bi-envelope-slash-fill::before {
  content: ""; }

.bi-envelope-slash::before {
  content: ""; }

.bi-envelope-x-fill::before {
  content: ""; }

.bi-envelope-x::before {
  content: ""; }

.bi-explicit-fill::before {
  content: ""; }

.bi-explicit::before {
  content: ""; }

.bi-git::before {
  content: ""; }

.bi-infinity::before {
  content: ""; }

.bi-list-columns-reverse::before {
  content: ""; }

.bi-list-columns::before {
  content: ""; }

.bi-meta::before {
  content: ""; }

.bi-nintendo-switch::before {
  content: ""; }

.bi-pc-display-horizontal::before {
  content: ""; }

.bi-pc-display::before {
  content: ""; }

.bi-pc-horizontal::before {
  content: ""; }

.bi-pc::before {
  content: ""; }

.bi-playstation::before {
  content: ""; }

.bi-plus-slash-minus::before {
  content: ""; }

.bi-projector-fill::before {
  content: ""; }

.bi-projector::before {
  content: ""; }

.bi-qr-code-scan::before {
  content: ""; }

.bi-qr-code::before {
  content: ""; }

.bi-quora::before {
  content: ""; }

.bi-quote::before {
  content: ""; }

.bi-robot::before {
  content: ""; }

.bi-send-check-fill::before {
  content: ""; }

.bi-send-check::before {
  content: ""; }

.bi-send-dash-fill::before {
  content: ""; }

.bi-send-dash::before {
  content: ""; }

.bi-send-exclamation-fill::before {
  content: ""; }

.bi-send-exclamation::before {
  content: ""; }

.bi-send-fill::before {
  content: ""; }

.bi-send-plus-fill::before {
  content: ""; }

.bi-send-plus::before {
  content: ""; }

.bi-send-slash-fill::before {
  content: ""; }

.bi-send-slash::before {
  content: ""; }

.bi-send-x-fill::before {
  content: ""; }

.bi-send-x::before {
  content: ""; }

.bi-send::before {
  content: ""; }

.bi-steam::before {
  content: ""; }

.bi-terminal-dash::before {
  content: ""; }

.bi-terminal-plus::before {
  content: ""; }

.bi-terminal-split::before {
  content: ""; }

.bi-ticket-detailed-fill::before {
  content: ""; }

.bi-ticket-detailed::before {
  content: ""; }

.bi-ticket-fill::before {
  content: ""; }

.bi-ticket-perforated-fill::before {
  content: ""; }

.bi-ticket-perforated::before {
  content: ""; }

.bi-ticket::before {
  content: ""; }

.bi-tiktok::before {
  content: ""; }

.bi-window-dash::before {
  content: ""; }

.bi-window-desktop::before {
  content: ""; }

.bi-window-fullscreen::before {
  content: ""; }

.bi-window-plus::before {
  content: ""; }

.bi-window-split::before {
  content: ""; }

.bi-window-stack::before {
  content: ""; }

.bi-window-x::before {
  content: ""; }

.bi-xbox::before {
  content: ""; }

.bi-ethernet::before {
  content: ""; }

.bi-hdmi-fill::before {
  content: ""; }

.bi-hdmi::before {
  content: ""; }

.bi-usb-c-fill::before {
  content: ""; }

.bi-usb-c::before {
  content: ""; }

.bi-usb-fill::before {
  content: ""; }

.bi-usb-plug-fill::before {
  content: ""; }

.bi-usb-plug::before {
  content: ""; }

.bi-usb-symbol::before {
  content: ""; }

.bi-usb::before {
  content: ""; }

.bi-boombox-fill::before {
  content: ""; }

.bi-displayport::before {
  content: ""; }

.bi-gpu-card::before {
  content: ""; }

.bi-memory::before {
  content: ""; }

.bi-modem-fill::before {
  content: ""; }

.bi-modem::before {
  content: ""; }

.bi-motherboard-fill::before {
  content: ""; }

.bi-motherboard::before {
  content: ""; }

.bi-optical-audio-fill::before {
  content: ""; }

.bi-optical-audio::before {
  content: ""; }

.bi-pci-card::before {
  content: ""; }

.bi-router-fill::before {
  content: ""; }

.bi-router::before {
  content: ""; }

.bi-thunderbolt-fill::before {
  content: ""; }

.bi-thunderbolt::before {
  content: ""; }

.bi-usb-drive-fill::before {
  content: ""; }

.bi-usb-drive::before {
  content: ""; }

.bi-usb-micro-fill::before {
  content: ""; }

.bi-usb-micro::before {
  content: ""; }

.bi-usb-mini-fill::before {
  content: ""; }

.bi-usb-mini::before {
  content: ""; }

.bi-cloud-haze2::before {
  content: ""; }

.bi-device-hdd-fill::before {
  content: ""; }

.bi-device-hdd::before {
  content: ""; }

.bi-device-ssd-fill::before {
  content: ""; }

.bi-device-ssd::before {
  content: ""; }

.bi-displayport-fill::before {
  content: ""; }

.bi-mortarboard-fill::before {
  content: ""; }

.bi-mortarboard::before {
  content: ""; }

.bi-terminal-x::before {
  content: ""; }

.bi-arrow-through-heart-fill::before {
  content: ""; }

.bi-arrow-through-heart::before {
  content: ""; }

.bi-badge-sd-fill::before {
  content: ""; }

.bi-badge-sd::before {
  content: ""; }

.bi-bag-heart-fill::before {
  content: ""; }

.bi-bag-heart::before {
  content: ""; }

.bi-balloon-fill::before {
  content: ""; }

.bi-balloon-heart-fill::before {
  content: ""; }

.bi-balloon-heart::before {
  content: ""; }

.bi-balloon::before {
  content: ""; }

.bi-box2-fill::before {
  content: ""; }

.bi-box2-heart-fill::before {
  content: ""; }

.bi-box2-heart::before {
  content: ""; }

.bi-box2::before {
  content: ""; }

.bi-braces-asterisk::before {
  content: ""; }

.bi-calendar-heart-fill::before {
  content: ""; }

.bi-calendar-heart::before {
  content: ""; }

.bi-calendar2-heart-fill::before {
  content: ""; }

.bi-calendar2-heart::before {
  content: ""; }

.bi-chat-heart-fill::before {
  content: ""; }

.bi-chat-heart::before {
  content: ""; }

.bi-chat-left-heart-fill::before {
  content: ""; }

.bi-chat-left-heart::before {
  content: ""; }

.bi-chat-right-heart-fill::before {
  content: ""; }

.bi-chat-right-heart::before {
  content: ""; }

.bi-chat-square-heart-fill::before {
  content: ""; }

.bi-chat-square-heart::before {
  content: ""; }

.bi-clipboard-check-fill::before {
  content: ""; }

.bi-clipboard-data-fill::before {
  content: ""; }

.bi-clipboard-fill::before {
  content: ""; }

.bi-clipboard-heart-fill::before {
  content: ""; }

.bi-clipboard-heart::before {
  content: ""; }

.bi-clipboard-minus-fill::before {
  content: ""; }

.bi-clipboard-plus-fill::before {
  content: ""; }

.bi-clipboard-pulse::before {
  content: ""; }

.bi-clipboard-x-fill::before {
  content: ""; }

.bi-clipboard2-check-fill::before {
  content: ""; }

.bi-clipboard2-check::before {
  content: ""; }

.bi-clipboard2-data-fill::before {
  content: ""; }

.bi-clipboard2-data::before {
  content: ""; }

.bi-clipboard2-fill::before {
  content: ""; }

.bi-clipboard2-heart-fill::before {
  content: ""; }

.bi-clipboard2-heart::before {
  content: ""; }

.bi-clipboard2-minus-fill::before {
  content: ""; }

.bi-clipboard2-minus::before {
  content: ""; }

.bi-clipboard2-plus-fill::before {
  content: ""; }

.bi-clipboard2-plus::before {
  content: ""; }

.bi-clipboard2-pulse-fill::before {
  content: ""; }

.bi-clipboard2-pulse::before {
  content: ""; }

.bi-clipboard2-x-fill::before {
  content: ""; }

.bi-clipboard2-x::before {
  content: ""; }

.bi-clipboard2::before {
  content: ""; }

.bi-emoji-kiss-fill::before {
  content: ""; }

.bi-emoji-kiss::before {
  content: ""; }

.bi-envelope-heart-fill::before {
  content: ""; }

.bi-envelope-heart::before {
  content: ""; }

.bi-envelope-open-heart-fill::before {
  content: ""; }

.bi-envelope-open-heart::before {
  content: ""; }

.bi-envelope-paper-fill::before {
  content: ""; }

.bi-envelope-paper-heart-fill::before {
  content: ""; }

.bi-envelope-paper-heart::before {
  content: ""; }

.bi-envelope-paper::before {
  content: ""; }

.bi-filetype-aac::before {
  content: ""; }

.bi-filetype-ai::before {
  content: ""; }

.bi-filetype-bmp::before {
  content: ""; }

.bi-filetype-cs::before {
  content: ""; }

.bi-filetype-css::before {
  content: ""; }

.bi-filetype-csv::before {
  content: ""; }

.bi-filetype-doc::before {
  content: ""; }

.bi-filetype-docx::before {
  content: ""; }

.bi-filetype-exe::before {
  content: ""; }

.bi-filetype-gif::before {
  content: ""; }

.bi-filetype-heic::before {
  content: ""; }

.bi-filetype-html::before {
  content: ""; }

.bi-filetype-java::before {
  content: ""; }

.bi-filetype-jpg::before {
  content: ""; }

.bi-filetype-js::before {
  content: ""; }

.bi-filetype-jsx::before {
  content: ""; }

.bi-filetype-key::before {
  content: ""; }

.bi-filetype-m4p::before {
  content: ""; }

.bi-filetype-md::before {
  content: ""; }

.bi-filetype-mdx::before {
  content: ""; }

.bi-filetype-mov::before {
  content: ""; }

.bi-filetype-mp3::before {
  content: ""; }

.bi-filetype-mp4::before {
  content: ""; }

.bi-filetype-otf::before {
  content: ""; }

.bi-filetype-pdf::before {
  content: ""; }

.bi-filetype-php::before {
  content: ""; }

.bi-filetype-png::before {
  content: ""; }

.bi-filetype-ppt::before {
  content: ""; }

.bi-filetype-psd::before {
  content: ""; }

.bi-filetype-py::before {
  content: ""; }

.bi-filetype-raw::before {
  content: ""; }

.bi-filetype-rb::before {
  content: ""; }

.bi-filetype-sass::before {
  content: ""; }

.bi-filetype-scss::before {
  content: ""; }

.bi-filetype-sh::before {
  content: ""; }

.bi-filetype-svg::before {
  content: ""; }

.bi-filetype-tiff::before {
  content: ""; }

.bi-filetype-tsx::before {
  content: ""; }

.bi-filetype-ttf::before {
  content: ""; }

.bi-filetype-txt::before {
  content: ""; }

.bi-filetype-wav::before {
  content: ""; }

.bi-filetype-woff::before {
  content: ""; }

.bi-filetype-xls::before {
  content: ""; }

.bi-filetype-xml::before {
  content: ""; }

.bi-filetype-yml::before {
  content: ""; }

.bi-heart-arrow::before {
  content: ""; }

.bi-heart-pulse-fill::before {
  content: ""; }

.bi-heart-pulse::before {
  content: ""; }

.bi-heartbreak-fill::before {
  content: ""; }

.bi-heartbreak::before {
  content: ""; }

.bi-hearts::before {
  content: ""; }

.bi-hospital-fill::before {
  content: ""; }

.bi-hospital::before {
  content: ""; }

.bi-house-heart-fill::before {
  content: ""; }

.bi-house-heart::before {
  content: ""; }

.bi-incognito::before {
  content: ""; }

.bi-magnet-fill::before {
  content: ""; }

.bi-magnet::before {
  content: ""; }

.bi-person-heart::before {
  content: ""; }

.bi-person-hearts::before {
  content: ""; }

.bi-phone-flip::before {
  content: ""; }

.bi-plugin::before {
  content: ""; }

.bi-postage-fill::before {
  content: ""; }

.bi-postage-heart-fill::before {
  content: ""; }

.bi-postage-heart::before {
  content: ""; }

.bi-postage::before {
  content: ""; }

.bi-postcard-fill::before {
  content: ""; }

.bi-postcard-heart-fill::before {
  content: ""; }

.bi-postcard-heart::before {
  content: ""; }

.bi-postcard::before {
  content: ""; }

.bi-search-heart-fill::before {
  content: ""; }

.bi-search-heart::before {
  content: ""; }

.bi-sliders2-vertical::before {
  content: ""; }

.bi-sliders2::before {
  content: ""; }

.bi-trash3-fill::before {
  content: ""; }

.bi-trash3::before {
  content: ""; }

.bi-valentine::before {
  content: ""; }

.bi-valentine2::before {
  content: ""; }

.bi-wrench-adjustable-circle-fill::before {
  content: ""; }

.bi-wrench-adjustable-circle::before {
  content: ""; }

.bi-wrench-adjustable::before {
  content: ""; }

.bi-filetype-json::before {
  content: ""; }

.bi-filetype-pptx::before {
  content: ""; }

.bi-filetype-xlsx::before {
  content: ""; }

.bi-1-circle-fill::before {
  content: ""; }

.bi-1-circle::before {
  content: ""; }

.bi-1-square-fill::before {
  content: ""; }

.bi-1-square::before {
  content: ""; }

.bi-2-circle-fill::before {
  content: ""; }

.bi-2-circle::before {
  content: ""; }

.bi-2-square-fill::before {
  content: ""; }

.bi-2-square::before {
  content: ""; }

.bi-3-circle-fill::before {
  content: ""; }

.bi-3-circle::before {
  content: ""; }

.bi-3-square-fill::before {
  content: ""; }

.bi-3-square::before {
  content: ""; }

.bi-4-circle-fill::before {
  content: ""; }

.bi-4-circle::before {
  content: ""; }

.bi-4-square-fill::before {
  content: ""; }

.bi-4-square::before {
  content: ""; }

.bi-5-circle-fill::before {
  content: ""; }

.bi-5-circle::before {
  content: ""; }

.bi-5-square-fill::before {
  content: ""; }

.bi-5-square::before {
  content: ""; }

.bi-6-circle-fill::before {
  content: ""; }

.bi-6-circle::before {
  content: ""; }

.bi-6-square-fill::before {
  content: ""; }

.bi-6-square::before {
  content: ""; }

.bi-7-circle-fill::before {
  content: ""; }

.bi-7-circle::before {
  content: ""; }

.bi-7-square-fill::before {
  content: ""; }

.bi-7-square::before {
  content: ""; }

.bi-8-circle-fill::before {
  content: ""; }

.bi-8-circle::before {
  content: ""; }

.bi-8-square-fill::before {
  content: ""; }

.bi-8-square::before {
  content: ""; }

.bi-9-circle-fill::before {
  content: ""; }

.bi-9-circle::before {
  content: ""; }

.bi-9-square-fill::before {
  content: ""; }

.bi-9-square::before {
  content: ""; }

.bi-airplane-engines-fill::before {
  content: ""; }

.bi-airplane-engines::before {
  content: ""; }

.bi-airplane-fill::before {
  content: ""; }

.bi-airplane::before {
  content: ""; }

.bi-alexa::before {
  content: ""; }

.bi-alipay::before {
  content: ""; }

.bi-android::before {
  content: ""; }

.bi-android2::before {
  content: ""; }

.bi-box-fill::before {
  content: ""; }

.bi-box-seam-fill::before {
  content: ""; }

.bi-browser-chrome::before {
  content: ""; }

.bi-browser-edge::before {
  content: ""; }

.bi-browser-firefox::before {
  content: ""; }

.bi-browser-safari::before {
  content: ""; }

.bi-c-circle-fill::before {
  content: ""; }

.bi-c-circle::before {
  content: ""; }

.bi-c-square-fill::before {
  content: ""; }

.bi-c-square::before {
  content: ""; }

.bi-capsule-pill::before {
  content: ""; }

.bi-capsule::before {
  content: ""; }

.bi-car-front-fill::before {
  content: ""; }

.bi-car-front::before {
  content: ""; }

.bi-cassette-fill::before {
  content: ""; }

.bi-cassette::before {
  content: ""; }

.bi-cc-circle-fill::before {
  content: ""; }

.bi-cc-circle::before {
  content: ""; }

.bi-cc-square-fill::before {
  content: ""; }

.bi-cc-square::before {
  content: ""; }

.bi-cup-hot-fill::before {
  content: ""; }

.bi-cup-hot::before {
  content: ""; }

.bi-currency-rupee::before {
  content: ""; }

.bi-dropbox::before {
  content: ""; }

.bi-escape::before {
  content: ""; }

.bi-fast-forward-btn-fill::before {
  content: ""; }

.bi-fast-forward-btn::before {
  content: ""; }

.bi-fast-forward-circle-fill::before {
  content: ""; }

.bi-fast-forward-circle::before {
  content: ""; }

.bi-fast-forward-fill::before {
  content: ""; }

.bi-fast-forward::before {
  content: ""; }

.bi-filetype-sql::before {
  content: ""; }

.bi-fire::before {
  content: ""; }

.bi-google-play::before {
  content: ""; }

.bi-h-circle-fill::before {
  content: ""; }

.bi-h-circle::before {
  content: ""; }

.bi-h-square-fill::before {
  content: ""; }

.bi-h-square::before {
  content: ""; }

.bi-indent::before {
  content: ""; }

.bi-lungs-fill::before {
  content: ""; }

.bi-lungs::before {
  content: ""; }

.bi-microsoft-teams::before {
  content: ""; }

.bi-p-circle-fill::before {
  content: ""; }

.bi-p-circle::before {
  content: ""; }

.bi-p-square-fill::before {
  content: ""; }

.bi-p-square::before {
  content: ""; }

.bi-pass-fill::before {
  content: ""; }

.bi-pass::before {
  content: ""; }

.bi-prescription::before {
  content: ""; }

.bi-prescription2::before {
  content: ""; }

.bi-r-circle-fill::before {
  content: ""; }

.bi-r-circle::before {
  content: ""; }

.bi-r-square-fill::before {
  content: ""; }

.bi-r-square::before {
  content: ""; }

.bi-repeat-1::before {
  content: ""; }

.bi-repeat::before {
  content: ""; }

.bi-rewind-btn-fill::before {
  content: ""; }

.bi-rewind-btn::before {
  content: ""; }

.bi-rewind-circle-fill::before {
  content: ""; }

.bi-rewind-circle::before {
  content: ""; }

.bi-rewind-fill::before {
  content: ""; }

.bi-rewind::before {
  content: ""; }

.bi-train-freight-front-fill::before {
  content: ""; }

.bi-train-freight-front::before {
  content: ""; }

.bi-train-front-fill::before {
  content: ""; }

.bi-train-front::before {
  content: ""; }

.bi-train-lightrail-front-fill::before {
  content: ""; }

.bi-train-lightrail-front::before {
  content: ""; }

.bi-truck-front-fill::before {
  content: ""; }

.bi-truck-front::before {
  content: ""; }

.bi-ubuntu::before {
  content: ""; }

.bi-unindent::before {
  content: ""; }

.bi-unity::before {
  content: ""; }

.bi-universal-access-circle::before {
  content: ""; }

.bi-universal-access::before {
  content: ""; }

.bi-virus::before {
  content: ""; }

.bi-virus2::before {
  content: ""; }

.bi-wechat::before {
  content: ""; }

.bi-yelp::before {
  content: ""; }

.bi-sign-stop-fill::before {
  content: ""; }

.bi-sign-stop-lights-fill::before {
  content: ""; }

.bi-sign-stop-lights::before {
  content: ""; }

.bi-sign-stop::before {
  content: ""; }

.bi-sign-turn-left-fill::before {
  content: ""; }

.bi-sign-turn-left::before {
  content: ""; }

.bi-sign-turn-right-fill::before {
  content: ""; }

.bi-sign-turn-right::before {
  content: ""; }

.bi-sign-turn-slight-left-fill::before {
  content: ""; }

.bi-sign-turn-slight-left::before {
  content: ""; }

.bi-sign-turn-slight-right-fill::before {
  content: ""; }

.bi-sign-turn-slight-right::before {
  content: ""; }

.bi-sign-yield-fill::before {
  content: ""; }

.bi-sign-yield::before {
  content: ""; }

.bi-ev-station-fill::before {
  content: ""; }

.bi-ev-station::before {
  content: ""; }

.bi-fuel-pump-diesel-fill::before {
  content: ""; }

.bi-fuel-pump-diesel::before {
  content: ""; }

.bi-fuel-pump-fill::before {
  content: ""; }

.bi-fuel-pump::before {
  content: ""; }

.bi-0-circle-fill::before {
  content: ""; }

.bi-0-circle::before {
  content: ""; }

.bi-0-square-fill::before {
  content: ""; }

.bi-0-square::before {
  content: ""; }

.bi-rocket-fill::before {
  content: ""; }

.bi-rocket-takeoff-fill::before {
  content: ""; }

.bi-rocket-takeoff::before {
  content: ""; }

.bi-rocket::before {
  content: ""; }

.bi-stripe::before {
  content: ""; }

.bi-subscript::before {
  content: ""; }

.bi-superscript::before {
  content: ""; }

.bi-trello::before {
  content: ""; }

.bi-envelope-at-fill::before {
  content: ""; }

.bi-envelope-at::before {
  content: ""; }

.bi-regex::before {
  content: ""; }

.bi-text-wrap::before {
  content: ""; }

.bi-sign-dead-end-fill::before {
  content: ""; }

.bi-sign-dead-end::before {
  content: ""; }

.bi-sign-do-not-enter-fill::before {
  content: ""; }

.bi-sign-do-not-enter::before {
  content: ""; }

.bi-sign-intersection-fill::before {
  content: ""; }

.bi-sign-intersection-side-fill::before {
  content: ""; }

.bi-sign-intersection-side::before {
  content: ""; }

.bi-sign-intersection-t-fill::before {
  content: ""; }

.bi-sign-intersection-t::before {
  content: ""; }

.bi-sign-intersection-y-fill::before {
  content: ""; }

.bi-sign-intersection-y::before {
  content: ""; }

.bi-sign-intersection::before {
  content: ""; }

.bi-sign-merge-left-fill::before {
  content: ""; }

.bi-sign-merge-left::before {
  content: ""; }

.bi-sign-merge-right-fill::before {
  content: ""; }

.bi-sign-merge-right::before {
  content: ""; }

.bi-sign-no-left-turn-fill::before {
  content: ""; }

.bi-sign-no-left-turn::before {
  content: ""; }

.bi-sign-no-parking-fill::before {
  content: ""; }

.bi-sign-no-parking::before {
  content: ""; }

.bi-sign-no-right-turn-fill::before {
  content: ""; }

.bi-sign-no-right-turn::before {
  content: ""; }

.bi-sign-railroad-fill::before {
  content: ""; }

.bi-sign-railroad::before {
  content: ""; }

.bi-building-add::before {
  content: ""; }

.bi-building-check::before {
  content: ""; }

.bi-building-dash::before {
  content: ""; }

.bi-building-down::before {
  content: ""; }

.bi-building-exclamation::before {
  content: ""; }

.bi-building-fill-add::before {
  content: ""; }

.bi-building-fill-check::before {
  content: ""; }

.bi-building-fill-dash::before {
  content: ""; }

.bi-building-fill-down::before {
  content: ""; }

.bi-building-fill-exclamation::before {
  content: ""; }

.bi-building-fill-gear::before {
  content: ""; }

.bi-building-fill-lock::before {
  content: ""; }

.bi-building-fill-slash::before {
  content: ""; }

.bi-building-fill-up::before {
  content: ""; }

.bi-building-fill-x::before {
  content: ""; }

.bi-building-fill::before {
  content: ""; }

.bi-building-gear::before {
  content: ""; }

.bi-building-lock::before {
  content: ""; }

.bi-building-slash::before {
  content: ""; }

.bi-building-up::before {
  content: ""; }

.bi-building-x::before {
  content: ""; }

.bi-buildings-fill::before {
  content: ""; }

.bi-buildings::before {
  content: ""; }

.bi-bus-front-fill::before {
  content: ""; }

.bi-bus-front::before {
  content: ""; }

.bi-ev-front-fill::before {
  content: ""; }

.bi-ev-front::before {
  content: ""; }

.bi-globe-americas::before {
  content: ""; }

.bi-globe-asia-australia::before {
  content: ""; }

.bi-globe-central-south-asia::before {
  content: ""; }

.bi-globe-europe-africa::before {
  content: ""; }

.bi-house-add-fill::before {
  content: ""; }

.bi-house-add::before {
  content: ""; }

.bi-house-check-fill::before {
  content: ""; }

.bi-house-check::before {
  content: ""; }

.bi-house-dash-fill::before {
  content: ""; }

.bi-house-dash::before {
  content: ""; }

.bi-house-down-fill::before {
  content: ""; }

.bi-house-down::before {
  content: ""; }

.bi-house-exclamation-fill::before {
  content: ""; }

.bi-house-exclamation::before {
  content: ""; }

.bi-house-gear-fill::before {
  content: ""; }

.bi-house-gear::before {
  content: ""; }

.bi-house-lock-fill::before {
  content: ""; }

.bi-house-lock::before {
  content: ""; }

.bi-house-slash-fill::before {
  content: ""; }

.bi-house-slash::before {
  content: ""; }

.bi-house-up-fill::before {
  content: ""; }

.bi-house-up::before {
  content: ""; }

.bi-house-x-fill::before {
  content: ""; }

.bi-house-x::before {
  content: ""; }

.bi-person-add::before {
  content: ""; }

.bi-person-down::before {
  content: ""; }

.bi-person-exclamation::before {
  content: ""; }

.bi-person-fill-add::before {
  content: ""; }

.bi-person-fill-check::before {
  content: ""; }

.bi-person-fill-dash::before {
  content: ""; }

.bi-person-fill-down::before {
  content: ""; }

.bi-person-fill-exclamation::before {
  content: ""; }

.bi-person-fill-gear::before {
  content: ""; }

.bi-person-fill-lock::before {
  content: ""; }

.bi-person-fill-slash::before {
  content: ""; }

.bi-person-fill-up::before {
  content: ""; }

.bi-person-fill-x::before {
  content: ""; }

.bi-person-gear::before {
  content: ""; }

.bi-person-lock::before {
  content: ""; }

.bi-person-slash::before {
  content: ""; }

.bi-person-up::before {
  content: ""; }

.bi-scooter::before {
  content: ""; }

.bi-taxi-front-fill::before {
  content: ""; }

.bi-taxi-front::before {
  content: ""; }

.bi-amd::before {
  content: ""; }

.bi-database-add::before {
  content: ""; }

.bi-database-check::before {
  content: ""; }

.bi-database-dash::before {
  content: ""; }

.bi-database-down::before {
  content: ""; }

.bi-database-exclamation::before {
  content: ""; }

.bi-database-fill-add::before {
  content: ""; }

.bi-database-fill-check::before {
  content: ""; }

.bi-database-fill-dash::before {
  content: ""; }

.bi-database-fill-down::before {
  content: ""; }

.bi-database-fill-exclamation::before {
  content: ""; }

.bi-database-fill-gear::before {
  content: ""; }

.bi-database-fill-lock::before {
  content: ""; }

.bi-database-fill-slash::before {
  content: ""; }

.bi-database-fill-up::before {
  content: ""; }

.bi-database-fill-x::before {
  content: ""; }

.bi-database-fill::before {
  content: ""; }

.bi-database-gear::before {
  content: ""; }

.bi-database-lock::before {
  content: ""; }

.bi-database-slash::before {
  content: ""; }

.bi-database-up::before {
  content: ""; }

.bi-database-x::before {
  content: ""; }

.bi-database::before {
  content: ""; }

.bi-houses-fill::before {
  content: ""; }

.bi-houses::before {
  content: ""; }

.bi-nvidia::before {
  content: ""; }

.bi-person-vcard-fill::before {
  content: ""; }

.bi-person-vcard::before {
  content: ""; }

.bi-sina-weibo::before {
  content: ""; }

.bi-tencent-qq::before {
  content: ""; }

.bi-wikipedia::before {
  content: ""; }

.bi-alphabet-uppercase::before {
  content: ""; }

.bi-alphabet::before {
  content: ""; }

.bi-amazon::before {
  content: ""; }

.bi-arrows-collapse-vertical::before {
  content: ""; }

.bi-arrows-expand-vertical::before {
  content: ""; }

.bi-arrows-vertical::before {
  content: ""; }

.bi-arrows::before {
  content: ""; }

.bi-ban-fill::before {
  content: ""; }

.bi-ban::before {
  content: ""; }

.bi-bing::before {
  content: ""; }

.bi-cake::before {
  content: ""; }

.bi-cake2::before {
  content: ""; }

.bi-cookie::before {
  content: ""; }

.bi-copy::before {
  content: ""; }

.bi-crosshair::before {
  content: ""; }

.bi-crosshair2::before {
  content: ""; }

.bi-emoji-astonished-fill::before {
  content: ""; }

.bi-emoji-astonished::before {
  content: ""; }

.bi-emoji-grimace-fill::before {
  content: ""; }

.bi-emoji-grimace::before {
  content: ""; }

.bi-emoji-grin-fill::before {
  content: ""; }

.bi-emoji-grin::before {
  content: ""; }

.bi-emoji-surprise-fill::before {
  content: ""; }

.bi-emoji-surprise::before {
  content: ""; }

.bi-emoji-tear-fill::before {
  content: ""; }

.bi-emoji-tear::before {
  content: ""; }

.bi-envelope-arrow-down-fill::before {
  content: ""; }

.bi-envelope-arrow-down::before {
  content: ""; }

.bi-envelope-arrow-up-fill::before {
  content: ""; }

.bi-envelope-arrow-up::before {
  content: ""; }

.bi-feather::before {
  content: ""; }

.bi-feather2::before {
  content: ""; }

.bi-floppy-fill::before {
  content: ""; }

.bi-floppy::before {
  content: ""; }

.bi-floppy2-fill::before {
  content: ""; }

.bi-floppy2::before {
  content: ""; }

.bi-gitlab::before {
  content: ""; }

.bi-highlighter::before {
  content: ""; }

.bi-marker-tip::before {
  content: ""; }

.bi-nvme-fill::before {
  content: ""; }

.bi-nvme::before {
  content: ""; }

.bi-opencollective::before {
  content: ""; }

.bi-pci-card-network::before {
  content: ""; }

.bi-pci-card-sound::before {
  content: ""; }

.bi-radar::before {
  content: ""; }

.bi-send-arrow-down-fill::before {
  content: ""; }

.bi-send-arrow-down::before {
  content: ""; }

.bi-send-arrow-up-fill::before {
  content: ""; }

.bi-send-arrow-up::before {
  content: ""; }

.bi-sim-slash-fill::before {
  content: ""; }

.bi-sim-slash::before {
  content: ""; }

.bi-sourceforge::before {
  content: ""; }

.bi-substack::before {
  content: ""; }

.bi-threads-fill::before {
  content: ""; }

.bi-threads::before {
  content: ""; }

.bi-transparency::before {
  content: ""; }

.bi-twitter-x::before {
  content: ""; }

.bi-type-h4::before {
  content: ""; }

.bi-type-h5::before {
  content: ""; }

.bi-type-h6::before {
  content: ""; }

.bi-backpack-fill::before {
  content: ""; }

.bi-backpack::before {
  content: ""; }

.bi-backpack2-fill::before {
  content: ""; }

.bi-backpack2::before {
  content: ""; }

.bi-backpack3-fill::before {
  content: ""; }

.bi-backpack3::before {
  content: ""; }

.bi-backpack4-fill::before {
  content: ""; }

.bi-backpack4::before {
  content: ""; }

.bi-brilliance::before {
  content: ""; }

.bi-cake-fill::before {
  content: ""; }

.bi-cake2-fill::before {
  content: ""; }

.bi-duffle-fill::before {
  content: ""; }

.bi-duffle::before {
  content: ""; }

.bi-exposure::before {
  content: ""; }

.bi-gender-neuter::before {
  content: ""; }

.bi-highlights::before {
  content: ""; }

.bi-luggage-fill::before {
  content: ""; }

.bi-luggage::before {
  content: ""; }

.bi-mailbox-flag::before {
  content: ""; }

.bi-mailbox2-flag::before {
  content: ""; }

.bi-noise-reduction::before {
  content: ""; }

.bi-passport-fill::before {
  content: ""; }

.bi-passport::before {
  content: ""; }

.bi-person-arms-up::before {
  content: ""; }

.bi-person-raised-hand::before {
  content: ""; }

.bi-person-standing-dress::before {
  content: ""; }

.bi-person-standing::before {
  content: ""; }

.bi-person-walking::before {
  content: ""; }

.bi-person-wheelchair::before {
  content: ""; }

.bi-shadows::before {
  content: ""; }

.bi-suitcase-fill::before {
  content: ""; }

.bi-suitcase-lg-fill::before {
  content: ""; }

.bi-suitcase-lg::before {
  content: ""; }

.bi-suitcase::before {
  content: "豈"; }

.bi-suitcase2-fill::before {
  content: "更"; }

.bi-suitcase2::before {
  content: "車"; }

.bi-vignette::before {
  content: "賈"; }

@font-face {
  font-family: "manrope";
  src: url("../fonts/manrope-regular-6.otf"); }

@font-face {
  font-family: "manrope-bold";
  src: url("../fonts/manrope-bold-2.otf"); }

@font-face {
  font-family: "manrope-light";
  src: url("../fonts/manrope-light-4.otf"); }

@font-face {
  font-family: "manrope-medium";
  src: url("../fonts/manrope-medium-5.otf"); }

body {
  font-family: "manrope", sans-serif; }

.block-our-data .info-wrap .info .number {
  font-weight: bold; }

.block-our-solution {
  font-family: "manrope-light", sans-serif; }
  .block-our-solution h4, .block-our-solution .h4 {
    font-family: "manrope-bold", sans-serif; }

.block-profile .content-1 h3, .block-profile .content-1 .h3 {
  font-family: "manrope-bold", sans-serif; }

.block-senior .data-header .title {
  font-family: "manrope-bold", sans-serif; }
  .block-senior .data-header .title small, .block-senior .data-header .title .small {
    font-family: "manrope-medium", sans-serif; }

.block-senior .data-details h4, .block-senior .data-details .h4 {
  font-family: "manrope-medium", sans-serif; }

.page-achievements-content .text-wrap h3, .page-achievements-content .text-wrap .h3 {
  font-family: "manrope-bold", sans-serif; }

.block-vmv .content h3, .block-vmv .content .h3,
.block-vmv .content-mission h4,
.block-vmv .content-mission .h4 {
  font-family: "manrope-bold", sans-serif; }

.page-header-2 .header-content h1, .page-header-2 .header-content .h1 {
  font-family: "manrope-bold", sans-serif; }

.block-networks .content-header h2, .block-networks .content-header .h2 {
  font-family: "manrope-bold", sans-serif; }

.block-offices .content-header h2, .block-offices .content-header .h2 {
  font-family: "manrope-bold", sans-serif; }

.block-offices .content h3, .block-offices .content .h3 {
  font-family: "manrope-bold", sans-serif; }

.block-offices .item-header h4, .block-offices .item-header .h4 {
  font-family: "manrope-bold", sans-serif; }

.block .html-content h1, .block .html-content .h1,
.block .html-content h2,
.block .html-content .h2,
.block .html-content h3,
.block .html-content .h3,
.block .html-content h4,
.block .html-content .h4,
.block .html-content h5,
.block .html-content .h5 {
  font-family: "manrope-bold", sans-serif; }

.block-contact .content-header h2, .block-contact .content-header .h2 {
  font-family: "manrope-bold", sans-serif; }

.block-contact .content h3, .block-contact .content .h3 {
  font-family: "manrope-bold", sans-serif; }

.block-other-city .content-other-city h3, .block-other-city .content-other-city .h3,
.block-enquiries .content-enquiries h3,
.block-enquiries .content-enquiries .h3 {
  font-family: "manrope-bold", sans-serif; }

.block-other-city .bg-text {
  font-family: "manrope-bold", sans-serif; }

.block-vmv .content-values .values-list .item h4, .block-vmv .content-values .values-list .item .h4 {
  font-family: "manrope-bold", sans-serif; }

.block-client-testimonials .content h2, .block-client-testimonials .content .h2 {
  font-family: "manrope-bold", sans-serif; }

.block-join .join-contact .btn {
  font-family: "manrope-bold", sans-serif; }

.block-publications .content h3, .block-publications .content .h3 {
  font-family: "manrope-bold", sans-serif; }

.block-enquiries .content-enquiries button.btn {
  font-family: "manrope-bold", sans-serif; }

.block-statement .content-header h2, .block-statement .content-header .h2 {
  font-family: "manrope-bold", sans-serif; }

.page-header-1 .content h1, .page-header-1 .content .h1 {
  font-family: "manrope-bold", sans-serif; }

.feedback .btn-feedback-ok {
  font-family: "manrope-bold", sans-serif; }

.block {
  margin: 0 auto;
  padding: 1rem 0;
  position: relative;
  overflow: hidden;
  color: #17171B;
  line-height: 1.6; }

@media (min-width: 768px) {
  .block {
    padding: 1.5rem 0; } }

@media (min-width: 992px) {
  .block {
    padding: 2rem 0; } }

@media (min-width: 1200px) {
  .block {
    padding: 3rem 0; } }

@media (min-width: 1400px) {
  .block {
    padding: 4rem 0; } }

.block a {
  text-decoration: none; }

.block img {
  max-width: 100%;
  height: auto; }

.block img.image-fw {
  width: 100%; }

.block .btn {
  width: 210px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.75rem 1rem; }
  .block .btn:hover {
    color: white;
    background: #f37921;
    border-color: #f37921; }

.block .survey-form-link {
  width: auto; }

.block .swiper-button-prev:hover,
.block .swiper-button-next:hover {
  color: white;
  opacity: 0.75; }

.block .content-container {
  padding: 0 0.75rem;
  position: relative; }

.block .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media (min-width: 1400px) {
  .block .content-container {
    max-width: 1590px;
    margin: 0 auto; } }

.block .content-header {
  margin: 0 0 0.75rem; }

.block .content-header h2, .block .content-header .h2 {
  font-size: 2rem;
  text-align: center;
  margin: 0;
  padding: 1rem 0;
  color: #153a5e; }

.block .content-header h3, .block .content-header .h3 {
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  padding: 1rem 0; }

@media (min-width: 768px) {
  .block .content-header {
    margin: 0 0 1rem; }
  .block .content-header h2, .block .content-header .h2 {
    font-size: 2.5rem; }
  .block .content-header h3, .block .content-header .h3 {
    font-size: 2rem; } }

@media (min-width: 992px) {
  .block .content-header {
    margin: 0 0 1.5rem; }
  .block .content-header h2, .block .content-header .h2 {
    font-size: 3rem; }
  .block .content-header h3, .block .content-header .h3 {
    font-size: 2.5rem; } }

@media (min-width: 1200px) {
  .block .content-header {
    margin: 0 0 2rem; }
  .block .content-header h2, .block .content-header .h2 {
    font-size: 3.5rem; }
  .block .content-header h3, .block .content-header .h3 {
    font-size: 3rem; } }

@media (min-width: 1400px) {
  .block .content-header {
    margin: 0 0 3rem; }
  .block .content-header h2, .block .content-header .h2 {
    font-size: 2.5rem; }
  .block .content-header h3, .block .content-header .h3 {
    font-size: 2.5rem; } }

.block-bg-1 {
  z-index: -1;
  background-image: url("../images/bg-2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0; }

@media (min-width: 768px) {
  .block-bg-1 {
    width: 1419px;
    height: 1479px;
    top: -31rem;
    left: 3rem; }
  .block-bg-1.style-1 {
    top: -18rem; }
  .block-bg-1.style-2 {
    left: 0; } }

@media (min-width: 992px) {
  .block-bg-1 {
    width: 1419px;
    height: 1479px;
    top: -31rem;
    left: 3rem; }
  .block-bg-1.style-1 {
    top: -18rem; }
  .block-bg-1.style-2 {
    left: 0; } }

@media (min-width: 1200px) {
  .block-bg-1 {
    width: 1419px;
    height: 1479px;
    top: -31rem;
    left: 3rem; }
  .block-bg-1.style-1 {
    top: -18rem; }
  .block-bg-1.style-2 {
    left: 0; } }

@media (min-width: 1400px) {
  .block-bg-1 {
    width: 1419px;
    height: 1479px;
    top: -31rem;
    left: 3rem; }
  .block-bg-1.style-1 {
    top: -18rem; }
  .block-bg-1.style-2 {
    left: 0; } }

.block-bg-2 {
  z-index: -1;
  background-image: url("../images/bg-3.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: absolute;
  width: 300px;
  height: 300px;
  right: -5rem;
  bottom: -4rem; }

@media (min-width: 576px) {
  .block-bg-2 {
    width: 400px;
    height: 400px;
    right: -10rem;
    bottom: -6rem; } }

@media (min-width: 768px) {
  .block-bg-2 {
    width: 500px;
    height: 500px;
    right: -12rem;
    bottom: -6rem; } }

@media (min-width: 992px) {
  .block-bg-2 {
    width: 600px;
    height: 600px;
    right: -15rem;
    bottom: -6rem; } }

@media (min-width: 1200px) {
  .block-bg-2 {
    width: 700px;
    height: 700px;
    right: -16rem;
    bottom: -6rem; } }

@media (min-width: 1400px) {
  .block-bg-2 {
    width: 800px;
    height: 800px;
    right: -19rem;
    bottom: -4rem; } }

.block-bg-3 {
  z-index: -1;
  background-image: url("../images/bg-4.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 40%;
  top: 26rem; }

@media (min-width: 576px) {
  .block-bg-3 {
    width: 80px;
    height: 80px;
    left: 32%;
    top: 15rem; } }

@media (min-width: 768px) {
  .block-bg-3 {
    width: 120px;
    height: 120px;
    left: 30%;
    top: 15rem; } }

@media (min-width: 992px) {
  .block-bg-3 {
    width: 160px;
    height: 160px;
    left: 30%;
    top: 22rem; } }

@media (min-width: 1200px) {
  .block-bg-3 {
    width: 200px;
    height: 200px;
    left: 30%;
    top: 19rem; } }

@media (min-width: 1400px) {
  .block-bg-3 {
    width: 228px;
    height: 228px;
    left: 36%;
    top: 21rem; }
  .block .btn {
    font-size: 0.875rem; } }

.header-bg-1,
.header-bg-2,
.header-bg-3 {
  z-index: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.header-bg-1 {
  background-image: url("../images/bg1.png");
  width: 477px;
  height: 497px;
  right: -1rem;
  top: -4rem; }

.header-bg-2 {
  background-image: url("../images/bg2.png");
  width: 517px;
  height: 517px;
  left: 32%;
  top: 0; }

.header-bg-3 {
  background-image: url("../images/bg3.png");
  width: 547px;
  height: 547px;
  left: 15%;
  top: 6rem; }

.item-bg {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/bg4.png");
  width: 757px;
  height: 757px;
  top: -4rem;
  right: -4rem;
  z-index: 0; }

.header {
  overflow: visible;
  background: white; }

.header .brand {
  width: 180px; }

.header .navbar-brand {
  width: 100%;
  display: block;
  height: 0;
  padding-bottom: calc((80 / 282) * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.header .navbar .nav-link.active,
.header .navbar .nav-link:focus,
.header .navbar .nav-link:hover {
  color: #f37921; }

.header .dropdown-menu a.dropdown-item.current,
.header .dropdown-menu a.dropdown-item:focus,
.header .dropdown-menu a.dropdown-item:hover {
  background: #153a5e;
  color: white; }

.header .current-lang {
  color: #f37921; }

@media (min-width: 992px) {
  .header .brand {
    width: 200px; }
  .header .lang-list {
    min-width: auto;
    width: 100%;
    text-align: center; } }

@media (min-width: 1200px) {
  .header .brand {
    width: 250px; }
  .header .navbar-nav .nav-link {
    padding-left: 0.75rem;
    padding-right: 0.75rem; } }

@media (min-width: 1400px) {
  .header.block {
    padding: 0.25rem 0; }
  .header .brand {
    width: 282px; }
  .header .navbar-nav {
    margin-right: 6rem; }
  .header .navbar-nav .nav-link {
    padding-left: 1.75rem;
    padding-right: 1.75rem; } }

.footer {
  color: white;
  background: #153A5E;
  font-size: 0.875rem; }

.footer .content {
  background: #153A5E; }

.footer .copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.2); }

.footer .content-right a {
  color: white; }

.footer .content-right a:hover {
  color: #f37921; }

.footer .nav .nav-link {
  padding: 0.25rem 0; }

.footer .nav-group-link {
  font-size: 1.25rem; }

.footer .nav {
  margin-bottom: 0.5rem; }

.footer .footer-block,
.footer .copyright {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.footer .copyright {
  color: rgba(255, 255, 255, 0.4); }

@media (min-width: 992px) {
  .footer .content-left {
    width: 30%; }
  .footer .content-right {
    width: 70%;
    border-left: 1px solid rgba(255, 255, 255, 0.2); }
  .footer .block-lg-left-inner {
    max-width: 275px; }
  .footer .footer-navs-1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .footer .footer-navs {
    padding-left: 10%;
    padding-right: 10%; } }

@media (min-width: 1400px) {
  .footer .content {
    padding-left: 9%; }
  .footer .footer-block h3, .footer .footer-block .h3 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    margin-top: 2rem; }
  .footer .copyright {
    padding: 0.5rem 0.75rem; }
  .footer .nav-group-link {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  .footer .nav .nav-link {
    line-height: 1.2;
    padding: 0.4rem 0; }
  .footer .footer-navs-1 {
    margin-top: 2rem; }
  .footer .footer-navs-2 .nav-links {
    width: 65%; }
  .footer .footer-navs-2 .social-links {
    width: 35%; }
  .footer .footer-navs-2 .social-links .nav-link {
    font-size: 1.25rem; } }

.modal-header .btn-close {
  background: none;
  font-size: 2rem; }

.form-modal .modal-content {
  background: #153a5e; }
  .form-modal .modal-content .modal-header {
    color: white;
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .form-modal .modal-content .btn-close {
    color: white; }

.feedback {
  padding: 1rem;
  font-size: 1.25rem; }

.feedback .feedback-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: center; }

.feedback .btn-feedback-ok {
  color: white;
  padding: 0.5rem 5rem;
  font-size: 1.25rem; }

.nova-form {
  background: #153a5e;
  border-radius: 0.75rem;
  padding: 1rem;
  color: white; }

.nova-form input,
.nova-form select,
.nova-form textarea {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0; }

.nova-form select option {
  color: #0a0a0a; }

.nova-form input:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ccc; }

.nova-form label {
  color: white; }

.nova-form .file-upload-component span {
  border: none;
  border-radius: 0;
  cursor: pointer;
  color: #000; }

.nova-form .file-upload-component span.file-name {
  background-color: rgba(255, 255, 255, 0.1);
  color: white; }

.nova-form input::-moz-placeholder, .nova-form textarea::-moz-placeholder {
  color: white; }

.nova-form input::placeholder,
.nova-form textarea::placeholder {
  color: white; }

.nova-form button.btn {
  width: 100%;
  justify-content: center;
  color: #153a5e;
  border-radius: 0;
  font-size: 1.5rem; }

@media (min-width: 992px) {
  .nova-form {
    border-radius: 1rem;
    padding: 1.5rem; }
  .nova-form input,
  .nova-form textarea,
  .nova-form select,
  .nova-form button.btn {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .nova-form .file-upload-component span {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; } }

@media (min-width: 1200px) {
  .nova-form {
    border-radius: 1.25rem;
    padding: 2rem; }
  .nova-form input,
  .nova-form select,
  .nova-form textarea,
  .nova-form button.btn {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .nova-form .file-upload-component span {
    padding-top: 1rem;
    padding-bottom: 1rem; } }

@media (min-width: 1400px) {
  .nova-form {
    border-radius: 1.5rem;
    padding: 2.5rem; }
  .nova-form input,
  .nova-form select,
  .nova-form textarea,
  .nova-form button.btn {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .nova-form button.btn {
    font-size: 1.75rem; }
  .nova-form .file-upload-component span {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; } }

.widget {
  z-index: 9999;
  position: fixed;
  right: 0;
  bottom: 60vh; }

.widget .btn-contact {
  background: rgba(243, 121, 33, 0.85);
  color: white;
  text-transform: uppercase;
  border-radius: 0;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  padding: 1rem 2rem;
  word-spacing: 0.5rem;
  border: none; }

.widget .btn-contact:hover {
  background: #f37921; }

@media (min-width: 992px) {
  .widget .btn-contact {
    font-size: 1.25rem;
    padding: 1.5rem 3rem; } }

.slideshow {
  --swiper-theme-color: #f57919;
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px; }

.slideshow-home1 {
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1; }

.slideshow-home1 .swiper-slide {
  color: white; }

.slideshow-home1 .swiper-slide .swiper-content {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: calc(100vh - 104px); }

.slideshow-home1 .swiper-slide .mask {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2); }

.slideshow-home1 .swiper-slide .content {
  z-index: 2;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3rem;
  top: auto; }

.slideshow-home1 .swiper-slide h2, .slideshow-home1 .swiper-slide .h2 {
  text-align: left; }

@media (min-width: 768px) {
  .slideshow-home1 .swiper-slide .content {
    bottom: 3rem; }
  .slideshow-home1 .swiper-slide .swiper-content {
    padding-bottom: calc(100vh - 120px); } }

@media (min-width: 992px) {
  .slideshow-home1 {
    --swiper-pagination-bullet-width: 14px;
    --swiper-pagination-bullet-height: 14px; }
  .slideshow-home1 .swiper-pagination {
    text-align: right;
    padding-right: 16%; }
  .slideshow-home1 .swiper-slide .content {
    bottom: 4rem; }
    .slideshow-home1 .swiper-slide .content h2, .slideshow-home1 .swiper-slide .content .h2 {
      font-size: 3.75rem; }
  .slideshow-home1 .swiper-slide .swiper-content {
    padding-bottom: 40vw; } }

@media (min-width: 1400px) {
  .slideshow-home1 .swiper-slide .content {
    padding-left: 9%; }
  .slideshow-home1 .swiper-slide .content {
    bottom: 5rem; }
    .slideshow-home1 .swiper-slide .content h2, .slideshow-home1 .swiper-slide .content .h2 {
      font-size: 3.75rem;
      margin-bottom: 1.5rem;
      font-weight: bold; }
    .slideshow-home1 .swiper-slide .content p {
      margin-bottom: 2.5rem;
      font-weight: bold;
      font-size: 1.5rem; } }

.block-about {
  background: #D6E9FB;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.block-about h2, .block-about .h2 {
  font-size: 1rem;
  text-align: right;
  text-transform: uppercase;
  font-weight: bold; }

.block-about h3, .block-about .h3 {
  font-size: 1.5rem; }

.block-about .description p {
  font-weight: bold; }

@media (min-width: 992px) {
  .block-about {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .block-about h3, .block-about .h3 {
    font-size: 2.5rem;
    margin: 1rem 0; } }

@media (min-width: 1400px) {
  .block-about {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem; }
  .block-about .content-wrap {
    padding-left: 9%; }
  .block-about .text-wrap {
    padding-top: 3rem;
    padding-right: 6%; }
  .block-about h3, .block-about .h3 {
    margin: 2rem 0; }
  .block-about .description {
    padding: 3rem 1rem 0 5.5rem; }
  .block-about .text-wrap {
    width: 53%; }
  .block-about .image-wrap {
    width: 47%; } }

.block-achievements {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.block-achievements .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center; }

.block-achievements .swiper-slide {
  height: 130px; }

.block-achievements .swiper-slide img {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%; }

.block-achievements .block-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold; }

@media (min-width: 992px) {
  .block-achievements {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .block-achievements .swiper-slide {
    height: 150px; }
  .block-achievements .block-title {
    margin-bottom: 2rem;
    font-size: 2rem; } }

@media (min-width: 1200px) {
  .block-achievements {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .block-achievements .swiper-slide {
    height: 190px; } }

@media (min-width: 1400px) {
  .block-achievements.block {
    padding: 4rem 0; }
  .block-achievements .block-title {
    margin-bottom: 2.5rem;
    font-size: 2.5rem; } }

.block-scope-services {
  --swiper-navigation-size: 1rem;
  --swiper-navigation-color: white; }

.block-scope-services .block-header {
  background: #153a5e;
  color: white;
  padding: 2rem 0; }
  .block-scope-services .block-header a {
    color: white; }

.block-scope-services .block-content {
  background: #f6f6f6;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.block-scope-services .block-header .swiper-buttons {
  position: relative;
  padding: 1rem 0 0.5rem;
  min-width: 90px; }

.block-scope-services .swiper-button-next,
.block-scope-services .swiper-button-prev {
  position: static;
  padding: 1rem;
  border-radius: 50%;
  background: #f37921; }

.slideshow-scope-services .swiper-wrapper .swiper-slide .content {
  background: white;
  padding: 2rem 1.5rem 1rem; }

.block-scope-services .block-content-2 {
  background-image: url("../images/bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.block-scope-services .block-header .slideshow-navs .link {
  font-weight: bold;
  font-size: 1rem; }

@media (min-width: 768px) {
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2 {
    font-size: 2rem; }
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2,
  .block-scope-services .block-header .slideshow-navs {
    width: 50%;
    padding: 3rem 0 1rem; }
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2 {
    text-align: left; }
  .block-scope-services .block-header .slideshow-navs {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 1rem; }
  .block-scope-services .swiper-wrapper .swiper-slide .content {
    min-height: 150px; } }

@media (min-width: 992px) {
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2 {
    font-size: 2.5rem; }
  .block-scope-services {
    --swiper-navigation-size: 1.5rem; }
  .block-scope-services .swiper-wrapper .swiper-slide h4, .block-scope-services .swiper-wrapper .swiper-slide .h4 {
    font-size: 1.125rem; }
  .block-scope-services .block-header .swiper-buttons {
    min-width: 150px; }
  .block-scope-services .swiper-button-next,
  .block-scope-services .swiper-button-prev {
    padding: 2rem; }
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2,
  .block-scope-services .block-header .slideshow-navs {
    padding: 5rem 0 2rem; }
  .block-scope-services .block-header .slideshow-navs {
    padding-left: 1.5rem; } }

@media (min-width: 1200px) {
  .slideshow-scope-services {
    padding-top: 1.5rem; }
  .block-scope-services .swiper-wrapper .swiper-slide {
    width: 475px; }
    .block-scope-services .swiper-wrapper .swiper-slide h4, .block-scope-services .swiper-wrapper .swiper-slide .h4 {
      font-size: 1.5rem; }
    .block-scope-services .swiper-wrapper .swiper-slide .content {
      min-height: 175px; }
  .block-scope-services .block-content {
    padding-top: 1.5rem;
    padding-bottom: 3rem; } }

@media (min-width: 1400px) {
  .block-scope-services .block-header {
    padding-left: 9%; }
  .block-scope-services .block-content {
    padding-left: 9%; }
  .block-scope-services .block-content-2 {
    padding-right: 9%;
    padding-bottom: 7rem; } }

.block-our-solutions {
  padding-top: 1rem; }

.block-our-solution {
  margin: 1rem 0; }

.block-our-solutions .block-title {
  margin: 1rem 0;
  font-size: 1.5rem;
  font-weight: bold; }

.block-our-solution .block-our-solution-block-header,
.block-our-solution .block-our-solution-block-content {
  padding: 1rem; }

.block-our-solution .block-our-solution-block-header {
  background: white;
  color: black; }

.block-our-solution .block-our-solution-block-header h4, .block-our-solution .block-our-solution-block-header .h4 {
  font-size: 1.25rem; }

.block-our-solution .block-our-solution-block-header .info .icon {
  color: #f37921;
  font-size: 1rem; }

.block-our-solution .block-our-solution-block-header .info .number {
  font-size: 4rem; }

.block-our-solution .block-our-solution-block-content {
  background: #153a5e;
  color: white; }

@media (min-width: 992px) {
  .block-our-solutions .block-title {
    margin: 2rem 0;
    font-size: 2rem; }
  .block-our-solution .block-our-solution-block-content {
    height: 190px; }
    .block-our-solution .block-our-solution-block-content p {
      max-width: 380px;
      font-size: 1.75rem;
      line-height: 1; }
  .block-our-solution .block-our-solution-block-header .info .icon {
    font-size: 1.5rem; } }

@media (min-width: 1400px) {
  .block-our-solutions .block-title {
    margin: 3rem 0;
    font-size: 2.5rem; }
  .block-our-solution .block-our-solution-block-header .info .icon {
    font-size: 2.5rem; }
  .block-our-solution .block-our-solution-block-header h4, .block-our-solution .block-our-solution-block-header .h4 {
    font-size: 1.5rem; }
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2,
  .block-scope-services .block-header .slideshow-navs {
    min-height: 180px;
    padding: 0; }
  .block-scope-services .block-header h2, .block-scope-services .block-header .h2 {
    padding: 0;
    display: flex;
    align-items: center; }
  .block-scope-services .block-header .slideshow-navs {
    display: flex;
    padding-left: 2rem;
    padding-bottom: 3rem;
    align-items: flex-end; }
  .block-scope-services .block-header .swiper-buttons {
    margin-top: 2rem;
    padding: 1rem 0 0.5rem;
    min-width: 180px; }
  .block-scope-services .swiper-button-next,
  .block-scope-services .swiper-button-prev {
    padding: 2.15rem; }
  .block-our-solution .block-our-solution-block-header {
    padding: 2.5rem 1.5rem;
    line-height: 1; }
  .block-our-solution .block-our-solution-block-content {
    padding: 0.5rem 1.5rem;
    min-height: 190px; }
  .block-our-solution .block-our-solution-block-header .info .icon {
    font-size: 3.25rem; }
  .block-our-solution .block-our-solution-block-header .info .number {
    font-size: 7.5rem; } }

.block-home-banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0; }

.block-home-banner .content {
  background: rgba(255, 255, 255, 0.6);
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-width: 545px;
  margin: 0 auto;
  color: #9B9B9B; }

.block-home-banner .content .btn {
  background: #977940;
  color: white; }
  .block-home-banner .content .btn:hover {
    background: #153a5e;
    border-color: #153a5e; }

.block-home-banner .content h2, .block-home-banner .content .h2 {
  font-size: 0.875rem;
  text-align: left;
  padding: 0;
  margin-bottom: 0.75rem; }

.block-home-banner .content h3, .block-home-banner .content .h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: black; }

@media (min-width: 768px) {
  .block-home-banner {
    padding: 2rem 0; }
  .block-home-banner .content {
    background: white;
    margin-right: 0.75rem;
    padding: 2rem; }
  .block-home-banner .content h3, .block-home-banner .content .h3 {
    font-size: 2.5rem; }
  .block-home-banner .content .description {
    margin-top: 1rem;
    margin-bottom: 1.5rem; } }

@media (min-width: 992px) {
  .block-home-banner {
    padding: 5rem 0; }
  .block-home-banner .content {
    padding-top: 3rem;
    padding-bottom: 3rem; } }

@media (min-width: 1200px) {
  .block-home-banner {
    padding: 8rem 0; } }

@media (min-width: 1400px) {
  .block-home-banner {
    padding: 10rem 0; }
  .block-home-banner .content-wrap {
    padding-right: 9%; }
  .block-home-banner .content h3, .block-home-banner .content .h3 {
    font-size: 2.375rem; }
  .block-home-banner .content .btn {
    margin-top: 2rem; } }

.block-client-testimonials {
  background: #ebebeb;
  padding-bottom: 1.5rem; }

.block-client-testimonials h2, .block-client-testimonials .h2 {
  text-align: left; }

.slideshow-client-testimonials {
  --swiper-navigation-size: 1rem;
  --swiper-navigation-color: white; }

.slideshow-client-testimonials .slide-content {
  background: white;
  background-image: url("../images/logo-small.png");
  background-repeat: no-repeat;
  background-position: 2rem 1.5rem;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 4.5rem 2rem 2rem; }

.slideshow-client-testimonials .swiper-buttons {
  position: relative;
  padding: 1rem 0 0.5rem;
  min-width: 90px; }

.slideshow-client-testimonials .swiper-button-next,
.slideshow-client-testimonials .swiper-button-prev {
  position: static;
  padding: 1rem;
  border-radius: 50%;
  background: #f37921; }

.slideshow-client-testimonials .swiper-pagination {
  width: auto;
  position: static;
  text-align: left; }

.block-client-testimonials .content h2, .block-client-testimonials .content .h2 {
  margin: 2rem 0; }

.slideshow-client-testimonials .slide-content .author {
  font-weight: bold;
  line-height: 1; }

.slideshow-client-testimonials .slide-content .name {
  margin-bottom: 1rem;
  font-weight: bold; }

@media (min-width: 992px) {
  .block-client-testimonials {
    padding-top: 2rem;
    padding-bottom: 5rem; }
  .block-client-testimonials .swiper-navs {
    margin-top: 3rem; }
  .slideshow-client-testimonials {
    --swiper-navigation-size: 1.5rem; }
  .slideshow-client-testimonials .swiper-buttons {
    min-width: 150px; }
  .slideshow-client-testimonials .swiper-button-next,
  .slideshow-client-testimonials .swiper-button-prev {
    padding: 2rem; }
  .slideshow-client-testimonials .slide-content {
    background-position: 3rem 2rem;
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    padding: 6rem 3rem 3rem; }
    .slideshow-client-testimonials .slide-content p {
      font-size: 1.5rem; }
  .block-client-testimonials .content h2, .block-client-testimonials .content .h2 {
    margin: 3rem 0; }
  .slideshow-client-testimonials .slide-content .name {
    font-size: 1.5rem; }
  .slideshow-client-testimonials .slide-content .title {
    font-size: 1.125rem; } }

@media (min-width: 1200px) {
  .block-client-testimonials .content h2, .block-client-testimonials .content .h2 {
    font-size: 2.5rem;
    margin: 4rem 0; }
  .block-client-testimonials {
    padding-top: 3rem;
    padding-bottom: 6rem; } }

@media (min-width: 1400px) {
  .block-client-testimonials {
    padding-top: 4rem;
    padding-bottom: 7rem; }
  .block-client-testimonials .content {
    padding-left: 9%;
    padding-right: 9%; } }

.block-join {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.block-join .btn {
  font-size: 0.75rem; }

.block-join .join-content {
  padding: 1rem 0;
  color: white; }

.block-join .join-content h2, .block-join .join-content .h2 {
  text-align: left;
  font-weight: bold; }

.block-join .join-contact {
  background: rgba(214, 233, 251, 0.9);
  padding: 1rem 0.75rem; }

.block-join .join-contact .btn {
  text-transform: uppercase;
  font-weight: bold; }
  .block-join .join-contact .btn i {
    color: #f37921; }

.block-join .join-contact .btn:hover i {
  color: #153a5e; }

@media (min-width: 768px) {
  .block-join .join-contact {
    font-size: 1.25rem; } }

@media (min-width: 992px) {
  .block-join {
    overflow: visible; }
  .block-join .join-content {
    font-size: 1.5rem;
    padding: 0 1rem; }
  .block-join .join-content .content-inner {
    max-width: 520px; }
  .block-join .join-contact {
    padding: 2rem 1rem;
    font-size: 1.75rem;
    width: 55%;
    position: relative;
    top: -1.75rem;
    border-top-right-radius: 1.75rem;
    background: #d6e9fb; } }

@media (min-width: 1200px) {
  .block-join .join-contact {
    padding: 2rem 1rem;
    font-size: 2rem;
    top: -2rem;
    bottom: 3rem;
    border-top-right-radius: 2rem; } }

@media (min-width: 1400px) {
  .block-join {
    height: 385px; }
  .block-join h2, .block-join .h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem; }
  .block-join .content {
    height: 100%;
    padding-right: 9%; }
  .block-join .join-content .content-inner {
    max-width: 540px;
    margin-top: 3rem;
    margin-left: 2rem; }
  .block-join .join-content {
    font-size: 1rem; }
  .block-join .join-contact {
    padding: 2.5rem 2rem;
    font-size: 2.25rem; }
  .block-offices .content h3, .block-offices .content .h3 {
    font-size: 1.875rem; } }

.page-header-1 {
  background: #F2F2F3;
  position: relative; }

.page-header-1 .mask {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.page-header-1 .page-banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 0;
  padding-bottom: calc(100vh - 104px);
  color: white; }
  .page-header-1 .page-banner .content-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.page-header-1 .current-location {
  position: relative;
  margin-bottom: 0.5rem; }
  .page-header-1 .current-location a, .page-header-1 .current-location span {
    color: white;
    padding: 0 0.75rem; }
  .page-header-1 .current-location a:hover {
    color: #f37921; }
  .page-header-1 .current-location a:first-child {
    padding-left: 0; }

.page-header-1 .page-nav {
  padding: 1rem 0; }

.page-header-1 .page-nav .nav-item {
  margin: 0.5rem;
  flex-grow: 1; }

.page-header-1 .page-nav .nav-link {
  background: white;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 0.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.page-header-1 .page-nav .nav-link.active,
.page-header-1 .page-nav .nav-link:hover {
  background: #153a5e;
  color: white; }

@media (min-width: 768px) {
  .page-header-1 .page-banner {
    padding-bottom: calc(100vh - 120px); }
  .page-header-1 .page-nav {
    padding: 1.5rem 0; } }

@media (min-width: 992px) {
  .page-header-1 .page-banner {
    padding-bottom: calc((380 / 1920) * 100%); }
  .page-header-1 .page-nav .nav-link {
    font-size: 1rem; }
  .page-header-1 .page-nav .nav-item:first-child {
    margin-left: 0; }
  .page-header-1 .page-nav .nav-item:last-child {
    margin-right: 0; } }

@media (min-width: 1400px) {
  .page-header-1 .content h1, .page-header-1 .content .h1 {
    font-size: 3.75em; }
  .page-header-1 .current-location {
    font-size: 1.25rem; }
  .page-header-1 .page-nav {
    padding: 2rem 0; }
  .page-header-1 .page-nav .nav-link {
    font-size: 1.25rem; } }

.page-header-2 {
  background: #E9EDF1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  position: relative; }

.page-header-2 .header-content {
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0;
  position: relative;
  z-index: 1; }

.page-header-2 .page-banner {
  position: relative;
  z-index: 1; }

.page-header-2 .header-content .page-title .content {
  margin-top: 2rem; }

.page-header-2 .header-content h1, .page-header-2 .header-content .h1 {
  color: #153a5e; }

.page-header-2 .current-location {
  position: relative;
  padding-left: 0.5rem;
  margin-bottom: 1rem; }
  .page-header-2 .current-location::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f37921;
    position: absolute;
    left: 0;
    top: 7px;
    bottom: 7px; }
  .page-header-2 .current-location a, .page-header-2 .current-location span {
    padding: 0 1rem; }
  .page-header-2 .current-location a:hover {
    color: #f37921; }

.page-header-2 .page-nav .nav-item {
  margin: 0.125rem; }

.page-header-2 .page-nav .nav-link {
  background: white;
  font-size: 0.75rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 0.25rem; }

.page-header-2 .page-nav .nav-link.active,
.page-header-2 .page-nav .nav-link:hover {
  background: #153a5e;
  color: white; }

@media (min-width: 768px) {
  .page-header-2 {
    padding-top: 1.5rem; }
  .page-header-2 .header-content {
    min-height: 180px; }
  .page-header-2 .page-nav .nav-item {
    margin-left: 0.75rem; } }

@media (min-width: 992px) {
  .page-header-2 {
    padding-top: 2rem; }
  .page-header-2 .page-nav .nav-link {
    font-size: 1rem; } }

@media (min-width: 1400px) {
  .page-header-2 .header-content h1, .page-header-2 .header-content .h1 {
    font-size: 3.75rem; }
  .page-header-2 .header-content {
    min-height: 310px; }
  .page-header-2 .header-content .page-title .content {
    margin-top: 3rem; }
  .page-header-2 .page-nav .nav-link {
    padding-left: 3rem;
    padding-right: 3rem; } }

.block-nova-insurance img.image {
  margin-bottom: 2rem; }

@media (min-width: 576px) {
  .block-nova-insurance .content-container {
    overflow: hidden;
    margin-bottom: 2rem; }
  .block-nova-insurance img.image {
    float: left;
    margin-right: 1rem;
    margin-bottom: 0;
    width: 38%; } }

@media (min-width: 768px) {
  .block-nova-insurance img.image {
    margin-right: 2rem; } }

@media (min-width: 992px) {
  .block-nova-insurance img.image {
    margin-right: 3rem; } }

@media (min-width: 1200px) {
  .block-nova-insurance img.image {
    margin-right: 4rem; } }

@media (min-width: 1400px) {
  .block-nova-insurance img.image {
    margin-right: 5rem; } }

.block-our-data {
  background: #F6F6F6;
  padding: 1rem 0 2rem 0; }

.block-our-data .item-ratio-1 {
  height: 0;
  padding-bottom: calc(300 / 560 * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative; }

.block-our-data .info-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  transition: 0.4s; }

.block-our-data .info-wrap .info {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
  transition: 0.4s; }

.block-our-data .info-wrap .info .number {
  font-size: 4rem; }

.block-our-data .info-wrap:hover {
  background: rgba(0, 0, 0, 0.5); }
  .block-our-data .info-wrap:hover .info {
    color: #f37921;
    margin-bottom: 2rem; }

@media (min-width: 992px) {
  .block-our-data {
    padding-top: 2rem;
    padding-bottom: 3rem; } }

@media (min-width: 1200px) {
  .block-our-data {
    padding: 4rem 0; }
  .block-our-data .content-header {
    min-width: 25%; } }

@media (min-width: 1400px) {
  .block-our-data {
    padding: 7rem 0; }
  .block-our-data .content .item {
    margin: 0.5rem; }
  .block-our-data .content .item .info-wrap {
    padding: 2rem; }
  .block-our-data .info-wrap .info .number {
    font-size: 5rem; } }

.block-fse .content-1 img.image {
  margin-bottom: 2rem; }

@media (min-width: 576px) {
  .block-fse .content-1 img.image {
    float: left;
    margin-right: 1rem;
    margin-bottom: 0;
    width: 45%; } }

@media (min-width: 768px) {
  .block-fse .content-1 {
    padding-bottom: 1rem; }
  .block-fse .content-1 img.image {
    margin-right: 2rem; }
  .block-fse .content-1 h3.content-title, .block-fse .content-1 .content-title.h3 {
    font-size: 2rem;
    margin-bottom: 1rem; } }

@media (min-width: 992px) {
  .block-fse .content-1 {
    padding-bottom: 2rem; }
  .block-fse .content-1 img.image {
    margin-right: 3rem; }
  .block-fse .content-1 h3.content-title, .block-fse .content-1 .content-title.h3 {
    font-size: 2.25rem;
    margin-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  .block-fse .content-1 {
    padding-bottom: 3rem; }
  .block-fse .content-1 img.image {
    margin-right: 4rem; }
  .block-fse .content-1 h3.content-title, .block-fse .content-1 .content-title.h3 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem; } }

@media (min-width: 1400px) {
  .block-fse .content-1 {
    padding-bottom: 6.5rem; }
  .block-fse .content-1 img.image {
    margin-right: 5rem; }
  .block-fse .content-1 h3.content-title, .block-fse .content-1 .content-title.h3 {
    font-size: 1.75rem;
    margin-bottom: 2rem; } }

.block-banner .content-2 {
  position: relative;
  margin: 1rem 0; }

.block-banner .content-2 .content-inner {
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.block-profile .content-header small, .block-profile .content-header .small {
  color: #9CA3A8; }

.block-profile .content-1 h3, .block-profile .content-1 .h3 {
  margin-bottom: 2rem; }

.block-profile .content-2 {
  padding: 2rem 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0 5.5rem, #153a5e 5.5rem 100%); }
  .block-profile .content-2 .html-content p {
    color: white;
    text-align: left;
    line-height: 1.75; }

.block-profile .content-2 .content-container {
  background: url("../images/profile-icon.png") no-repeat 0.75rem 0;
  padding-top: 7rem;
  color: white; }

.block-profile .content-3 {
  margin-top: 2rem; }

.block-profile .content-3 ul {
  padding: 0; }

.block-profile .content-3 li {
  padding: 1rem 0;
  border-top: 1px solid #F2F2F3;
  list-style: none; }

.block-profile .content-3 li:last-child {
  border-bottom: 1px solid #F2F2F3; }

.block-profile .content-3 .html-content li {
  position: relative;
  padding: 1.25rem 0 1.25rem 2rem; }

.block-profile .content-3 .html-content li:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: #f37921;
  margin-right: 0.5rem;
  position: absolute;
  left: 0;
  top: 34px; }

@media (min-width: 1200px) {
  .block-profile .content-2 .content-container {
    padding-top: 7rem; }
  .block-profile .content-2 .image-wrap {
    width: 52%;
    position: relative;
    top: -3rem; }
  .block-profile .content-3 {
    margin-top: 2rem; }
  .block-profile .content-3 li {
    padding: 2rem 0; } }

@media (min-width: 1400px) {
  .block-profile .content-3 {
    margin-top: 4rem; }
  .block-profile .content-1 h3, .block-profile .content-1 .h3 {
    margin-bottom: 3rem; }
  .block-profile .content-2 .content-container {
    padding-top: 3rem; }
  .block-profile .content-3 li {
    padding: 1rem 0; } }

.block-profile .content-2 .text-wrap {
  padding: 1rem 0; }

@media (min-width: 1200px) {
  .block-profile .content-2 .text-wrap {
    width: 48%;
    padding-right: 2rem; } }

.block-senior .data-header,
.block-senior .data-content {
  position: relative;
  z-index: 1; }

.block-senior .data-item {
  border-radius: 1rem;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1), -5px -5px 15px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1rem;
  margin-bottom: 3rem;
  background: white;
  position: relative; }

.block-senior .data-header {
  display: flex; }

.block-senior .image-wrap {
  max-width: 100px; }

.block-senior .data-header .title {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  line-height: 1; }

.block-senior .data-header .title small, .block-senior .data-header .title .small {
  font-size: 1.125rem; }

.block-senior .info-list {
  margin: 1rem 0;
  font-size: 0.875rem; }

.block-senior .info-list li {
  display: flex; }

.block-senior .info-list li i.bi {
  color: #f37921; }

.block-senior .info-list li span {
  margin-left: 0.5rem; }

@media (min-width: 992px) {
  .block-senior .data-item {
    position: relative;
    padding: 2rem;
    margin-bottom: 4rem; }
  .block-senior .data-header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
    min-width: 60%;
    z-index: 1; }
  .block-senior .data-header .title {
    margin-left: 2rem;
    margin-top: 0.75rem;
    position: absolute;
    left: 185px;
    top: 2rem;
    width: 70%;
    padding-left: 2.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background: linear-gradient(to right, #E5F2FF, transparent); }
  .block-senior .data-header .title small, .block-senior .data-header .title .small {
    margin-top: 0.5rem; }
  .block-senior .image-wrap {
    width: 200px;
    max-width: none; }
  .block-senior .data-content {
    padding-left: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 2; }
  .block-senior .info-list {
    margin: 1.5rem 0; }
  .block-senior .data-details {
    line-height: 2;
    margin-top: 1.5rem; }
  .block-senior .data-details h4, .block-senior .data-details .h4 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem; }
  .block-senior .data-details p {
    text-align: justify; } }

@media (min-width: 1400px) {
  .block-senior .data-item {
    margin-bottom: 6rem; }
  .block-senior .data-item {
    padding: 3rem 5rem; }
  .block-senior .data-header {
    padding: 3rem 5rem;
    min-width: 80%; }
  .block-senior .data-header .title {
    font-size: 1.875rem;
    top: 4rem;
    left: 280px;
    padding-left: 4.25rem;
    flex-direction: row;
    align-items: center; }
  .block-senior .data-header .title small, .block-senior .data-header .title .small {
    margin-left: 1rem; }
  .block-senior .image-wrap {
    width: 255px; }
  .block-senior .data-content {
    padding-left: 300px; }
  .block-senior .info-list {
    max-width: 370px; } }

.page-achievements-content .text-wrap h3, .page-achievements-content .text-wrap .h3 {
  color: #153a5e; }

.page-achievements-content .text-wrap h3, .page-achievements-content .text-wrap .h3 {
  margin-bottom: 1rem; }

.page-achievements-content .achievements-form {
  display: flex;
  justify-content: flex-end; }
  .page-achievements-content .achievements-form select.year-list {
    background-color: #FFBF91;
    border-color: #FFBF91;
    border-radius: 0;
    padding: 0.65rem 1rem;
    min-width: 214px;
    font-size: 1.125rem; }

.page-achievements-content hr {
  border-color: #6287AF;
  margin: 2rem 0; }

.page-achievements-content .cat-name {
  margin: 2rem 0;
  font-size: 2.5rem; }

.page-achievements-content table {
  background: white;
  border-collapse: separate;
  border-spacing: 1px 0; }
  .page-achievements-content table thead tr th {
    background-color: #6287AF;
    color: white; }
  .page-achievements-content table tbody td {
    vertical-align: middle; }
  .page-achievements-content table tbody td:nth-child(1) {
    font-weight: bold; }
  .page-achievements-content table tbody tr:nth-child(odd) td {
    background-color: #D6E9FB; }
  .page-achievements-content table tbody tr:nth-child(even) td {
    background-color: #C6DBEF; }

@media (min-width: 992px) {
  .page-achievements-content .text-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .page-achievements-content .load-more {
    padding: 2rem 0;
    margin-bottom: 3rem; }
  .page-achievements-content table th, .page-achievements-content table td {
    padding: 1rem 2rem; }
  .page-achievements-content table thead tr th {
    font-size: 1.25rem; }
  .page-achievements-content table tbody td:nth-child(1) {
    font-size: 1.5rem; } }

.page-achievements-content .achievement-slideshow {
  --swiper-theme-color: #f57919;
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  position: relative;
  padding-bottom: 2rem; }

.page-achievements-content .achievement-slideshow .swiper {
  border: 1px solid #E9EDF1; }

.page-achievements-content .content .text-wrap {
  margin-left: 0.5rem;
  font-size: 1rem; }

.page-achievements-content .content .text-wrap p {
  line-height: 2; }

@media (min-width: 1400px) {
  .page-achievements-content .text-wrap h3, .page-achievements-content .text-wrap .h3 {
    margin-bottom: 2.5rem;
    font-size: 1.5rem; }
  .page-achievements-content table th, .page-achievements-content table td {
    padding: 1.5rem 3rem; } }

.block-vmv .content-vision,
.block-vmv .content-mission,
.block-vmv .content-values {
  padding: 1rem 0; }

.block-vmv .content h3, .block-vmv .content .h3 {
  margin-bottom: 1rem;
  color: #153a5e;
  text-transform: uppercase; }


.block-vmv .content-mission h4,
.block-vmv .content-mission .h4 {
  position: relative;
  margin-bottom: 1.25rem;
  color: #153a5e; }

.block-vmv .content-mission h4:after, .block-vmv .content-mission .h4:after {
  content: "";
  height: 1px;
  width: 2.5em;
  background: #f37921;
  position: absolute;
  left: 0;
  bottom: -0.5rem; }

.block-vmv .content-values .values-list {
  margin-top: 2rem;
  padding: 0 2rem; }

.block-vmv .content-values .values-list .item {
  margin-bottom: 2rem; }

.block-vmv .content-values .values-list .item h4, .block-vmv .content-values .values-list .item .h4 {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem; }

.block-vmv .content-values .values-list .icon-wrap {
  background: #E9EDF1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 120px;
  height: 120px;
  border-radius: 0.5rem 2rem 0.5rem 2rem;
  transition: 0.4s; }

.block-vmv .content-values .values-list .item:hover .icon-wrap {
  border-radius: 50%;
  background: #f37921; }

.block-vmv .content-values .values-list .item:hover h4, .block-vmv .content-values .values-list .item:hover .h4 {
  color: #f37921; }

.block-vmv .content-values .values-list .icon-wrap img {
  width: 25px; }

@media (min-width: 576px) {
  .block-vmv .content-values .values-list .icon-wrap {
    width: 80px;
    height: 80px;
    border-radius: 0.5rem 2rem 0.5rem 2rem; } }

@media (min-width: 768px) {
  .block-vmv .content-values .values-list .icon-wrap {
    width: 100px;
    height: 100px;
    border-radius: 1rem 3rem 1rem 3rem; }
  .block-vmv .content-values .values-list .icon-wrap img {
    width: 30px; } }

@media (min-width: 992px) {
  .block-vmv .content-vision,
  .block-vmv .content-mission,
  .block-vmv .content-values {
    padding: 2rem 0; }
  .block-vmv .content-values .values-list {
    margin-top: 3rem; }
  .block-vmv .content-values .values-list .icon-wrap {
    width: 150px;
    height: 150px;
    border-radius: 2rem 5rem 2rem 5rem; }
  .block-vmv .content-values .values-list .icon-wrap img {
    width: 35px; } }

@media (min-width: 1200px) {
  .block-vmv .content-values .values-list .icon-wrap {
    width: 150px;
    height: 150px;
    border-radius: 2rem 5rem 2rem 5rem; }
  .block-vmv .content-vision {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .block-vmv .content-vision .content-details {
      width: 45%;
      padding-right: 2rem; }
    .block-vmv .content-vision .image-wrap {
      width: 55%; }
  .block-vmv .content-mission {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center; }
    .block-vmv .content-mission .content-details {
      width: 45%;
      padding-left: 2rem; }
    .block-vmv .content-mission .image-wrap {
      width: 55%; }
  
  .block-vmv .content-mission h4,
  .block-vmv .content-mission .h4 {
    font-size: 1.25rem; }
  .block-vmv .content-values .values-list .icon-wrap img {
    width: 40px; } }

@media (min-width: 1400px) {
  .block-vmv .content {
    font-size: 1.125rem; }
  .block-vmv .content-vision,
  .block-vmv .content-mission,
  .block-vmv .content-values {
    padding: 3rem 0; }
  .block-vmv .content-values {
    padding: 3rem; }
  .block-vmv .content-values .values-list .icon-wrap {
    width: 150px;
    height: 150px;
    border-radius: 2rem 5rem 2rem 5rem; }
  .block-vmv .content-vision .content-details {
    width: 42%;
    padding-right: 3rem; }
  .block-vmv .content-vision .image-wrap {
    width: 58%; }
  .block-vmv .content-mission .content-details {
    width: 42%;
    padding-left: 4rem; }
  .block-vmv .content-mission .image-wrap {
    width: 58%; }
  .block-vmv .content-mission li {
    padding: 0.5rem 0;
    margin-top: 1.25rem; }
  .block-vmv .content-values .values-list .icon-wrap img {
    width: 45px; }
  .block-vmv .content-values .values-list {
    margin-top: 4rem; }
  .block-vmv .content h3, .block-vmv .content .h3 {
    font-size: 1.875rem;
    margin-bottom: 2rem; }
  .block-vmv .content-values .values-list .item h4, .block-vmv .content-values .values-list .item .h4 {
    margin-top: 2rem;
    font-size: 1.125rem; } }

.block-networks .list-item {
  border-top: 1px solid #E5F2FF;
  padding: 2rem 0; }

.block-networks .brands-list {
  border-bottom: 1px solid #E5F2FF;
  margin-bottom: 1rem; }

.block-networks .brand-wrap {
  width: 150px; }

.block-networks .btn {
  width: auto; }

.block-networks .btn span {
  text-align: left;
  margin-right: 1rem; }

.block-networks .btn span:first-line {
  font-weight: bold; }

@media (min-width: 768px) {
  .block-networks .list-item {
    padding: 2rem 5rem; }
  .block-networks .brands-list {
    margin-bottom: 2rem; } }

@media (min-width: 992px) {
  .block-networks .list-item {
    padding: 2rem 6rem; }
  .block-networks .brand-wrap {
    width: auto; }
  .block-networks .brands-list {
    margin-bottom: 3rem;
    padding: 0 10rem; }
  .block-networks .btn span {
    font-size: 1.5rem; } }

@media (min-width: 1200px) {
  .block-networks .list-item {
    padding: 2rem 7rem; }
  .block-networks .brands-list {
    margin-bottom: 4rem; } }

@media (min-width: 1400px) {
  .block-networks .list-item {
    padding: 2rem 20rem 2rem 8rem; }
  .block-networks .list-item .inner {
    min-height: 200px; }
  .block-networks .brands-list {
    margin-bottom: 5rem; } }

.data-grid {
  margin-bottom: 1rem; }

.data-grid .data-item {
  display: block;
  transition: 0.4s; }

.data-grid .image-wrap {
  display: block;
  height: 0;
  padding-bottom: calc(280 / 490 * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.data-grid .text-wrap {
  position: relative;
  padding: 1rem;
  border: 1px solid #DFDFDF;
  background: white;
  transition: 0.4s; }

.data-grid .text-wrap .btn {
  position: absolute;
  top: -22px;
  right: 1.5rem;
  width: 44px;
  border-radius: 50%;
  color: white;
  transition: 0.4s; }

.data-grid .data-item:hover {
  color: white; }
  .data-grid .data-item:hover .btn {
    background: #153a5e;
    border-color: #153a5e; }
  .data-grid .data-item:hover .text-wrap {
    background: #153a5e;
    border: 1px solid #153a5e; }

@media (min-width: 768px) {
  .data-grid {
    margin-bottom: 2rem; }
  .data-grid .text-wrap {
    min-height: 170px; }
  .data-grid .text-wrap.text-wrap-88 {
    min-height: auto; } }

@media (min-width: 992px) {
  .data-grid {
    margin-bottom: 2rem; } }

@media (min-width: 1200px) {
  .data-grid {
    margin-bottom: 3rem; } }

@media (min-width: 1400px) {
  .data-grid {
    margin-bottom: 4rem; }
  .data-grid .text-wrap {
    padding: 2rem 1.5rem;
    min-height: 205px; }
  .data-grid .text-wrap.text-wrap-88 {
    min-height: auto; }
  .data-grid .text-wrap h3, .data-grid .text-wrap .h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem; }
  .data-grid .text-wrap p {
    color: #6D8295;
    font-size: 1rem;
    line-height: 1.3; }
  .data-grid .text-wrap .btn {
    top: -33px;
    width: 66px;
    height: 66px;
    font-size: 1.5rem; } }

.block-offices .item-header {
  border-radius: 0.5rem; }

.block-offices .item-header h4, .block-offices .item-header .h4 {
  line-height: 1;
  margin: 0;
  font-size: 1rem; }

.block-offices .offices-list {
  margin-bottom: 2rem; }

.block-offices .offices-list .office-item {
  margin: 1.25rem 0; }

.block-offices .offices-list .office-item .slide-content-wrap {
  height: 0;
  overflow: hidden; }

.block-offices .offices-list .item-header {
  background: #E9EDF1;
  transition: 0.4s;
  padding: 1rem; }

.block-offices .offices-list .office-item .item-header:hover,
.block-offices .offices-list .office-item.open .item-header {
  background: #153a5e;
  color: white;
  cursor: pointer; }

.block-offices .offices-list .slide-content {
  padding: 2rem 0; }

.block-offices .offices-list .slide-content .link {
  margin-top: 2rem; }

@media (min-width: 768px) {
  .block-offices .offices-list {
    margin-bottom: 3rem; } }

@media (min-width: 992px) {
  .block-offices .offices-list {
    margin-bottom: 4rem; }
  .block-offices .offices-list .item-header {
    padding: 1rem; }
  .block-offices .item-header h4, .block-offices .item-header .h4 {
    font-size: 1.25rem; }
  .block-offices .offices-list .slide-content .buttons {
    display: flex;
    justify-content: space-between;
    width: 500px; } }

@media (min-width: 1200px) {
  .block-offices .offices-list {
    margin-bottom: 5rem; }
  .block-offices .offices-list .item-header {
    padding: 1rem; } }

@media (min-width: 1400px) {
  .block-offices .offices-list .item-header {
    padding: 1.5rem; }
  .block-offices .offices-list .slide-content {
    padding-top: 2rem;
    padding-bottom: 3rem; }
  .block-offices .offices-list .office-item {
    margin: 1.75rem 0; } }

.block-contact {
  font-size: 1.125rem; }

.block-contact .description p {
  margin-bottom: 0.75rem;
  line-height: 2; }

.block-contact .content h3, .block-contact .content .h3 {
  color: #f37921;
  margin-bottom: 2rem; }

.block-contact .content .description {
  color: rgba(0, 0, 0, 0.5); }

.block-contact .content .info-list {
  font-size: 1.125rem; }

.block-contact .content .info-list li {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start; }

.block-contact .content .info-list li i {
  font-size: 1.25rem; }

.block-contact .content .info-list li span {
  margin-left: 1rem; }

.block-contact .content {
  position: relative;
  border-bottom: 3px solid #EFF5F9;
  margin-bottom: 2.5rem; }

.block-contact .content .decorate {
  background-image: url("../images/contact-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  height: 150px;
  width: 150px;
  border-bottom: 3px solid #f37921;
  position: absolute;
  bottom: -3px;
  right: 0; }

@media (min-width: 1400px) {
  .block-contact .content .decorate {
    height: 400px;
    width: 400px; }
  .block-contact .content h3, .block-contact .content .h3 {
    font-size: 1.875rem; } }

.block-contact .content {
  margin-bottom: 2.5rem; }

@media (min-width: 992px) {
  .block-contact .content {
    margin-bottom: 3rem; } }

@media (min-width: 1400px) {
  .block-contact .content {
    margin-bottom: 5rem; } }

.block-contact .info-list,
.block-contact .other-content,
.block-contact .emails-list {
  margin: 2rem 0; }

.block-contact .emails-list {
  line-height: 2; }

.block-contact .emails-list span {
  color: #f37921; }

.block-contact .social-links {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.block-contact .social-links a:hover {
  color: #f37921; }

@media (min-width: 768px) {
  .block-contact .social-links {
    width: 250px;
    justify-content: space-between;
    margin: 3rem 0; } }

@media (min-width: 1400px) {
  .block-contact .content .info-list {
    max-width: 580px;
    margin-bottom: 4rem; } }

.block-other-city {
  font-size: 1.125rem;
  position: relative; }

.block-other-city .bg-text {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 2rem;
  color: #EFF5F9;
  font-size: 7.75vw;
  text-align: center; }

.block-other-city .description p {
  margin-bottom: 0.75rem;
  line-height: 2; }

.block-other-city .content h3, .block-other-city .content .h3 {
  color: #f37921;
  margin-bottom: 2rem; }

.block-other-city .content .description {
  color: rgba(0, 0, 0, 0.5); }

.block-other-city .content .info-list {
  font-size: 1.125rem; }

.block-other-city .content .info-list li {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start; }

.block-other-city .content .info-list li i {
  font-size: 1.25rem; }

.block-other-city .content .info-list li span {
  margin-left: 1rem; }

.block-other-city .content {
  position: relative;
  border-bottom: 3px solid #EFF5F9;
  margin-bottom: 2.5rem; }

.block-other-city .content .decorate {
  background-image: url("../images/contact-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  height: 150px;
  width: 150px;
  border-bottom: 3px solid #f37921;
  position: absolute;
  bottom: -3px;
  right: 0; }

@media (min-width: 1400px) {
  .block-other-city .content .decorate {
    height: 400px;
    width: 400px; }
  .block-other-city .content h3, .block-other-city .content .h3 {
    font-size: 1.875rem; } }

.block-other-city .content-other-city {
  margin-bottom: 2.5rem; }

.content-other-city {
  position: relative; }

.content-other-city .bg-text {
  position: absolute;
  border: 1px solid red;
  width: 100%;
  height: 100%; }

@media (min-width: 992px) {
  .block-other-city .content-other-city {
    margin-bottom: 3rem; } }

@media (min-width: 1400px) {
  .block-other-city .content-other-city {
    margin-bottom: 5rem; } }

.block-other-city .info-list,
.block-other-city .other-content,
.block-other-city .emails-list {
  margin: 2rem 0; }

.block-other-city .emails-list {
  line-height: 2; }

.block-other-city .emails-list span {
  color: #f37921; }

.block-other-city .social-links {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.block-other-city .social-links a:hover {
  color: #f37921; }

@media (min-width: 768px) {
  .block-other-city .social-links {
    width: 250px;
    justify-content: space-between;
    margin: 3rem 0; } }

.block-other-city .content-other-city .city {
  display: block;
  height: 0;
  padding-bottom: calc(310 / 490 * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  transition: 0.4s; }

.block-other-city .content-other-city .city-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  display: flex;
  align-items: flex-end;
  transition: 0.4s;
  font-size: 1.5rem; }

.block-other-city .content-other-city .city .content-inner {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: 0.4s; }

.block-other-city .content-other-city .city:hover .city-content {
  height: 100%; }

.block-other-city .content-other-city .city:hover .content-inner {
  color: #f37921;
  font-size: 1.125em; }

@media (min-width: 992px) {
  .block-other-city .content-other-city .other-header {
    width: 36%;
    padding-right: 1rem; }
  .block-other-city .content-other-city .other-content {
    width: 64%; } }

@media (min-width: 1200px) {
  .block-other-city .content-other-city .other-header {
    padding-right: 2rem; } }

@media (min-width: 1400px) {
  .block-other-city .content-other-city .other-header {
    padding-right: 3rem; } }

.block-other-city .content-other-city h3, .block-other-city .content-other-city .h3 {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  color: #153a5e; }

@media (min-width: 992px) {
  .block-other-city .content-other-city h3, .block-other-city .content-other-city .h3 {
    margin-bottom: 2rem; } }

@media (min-width: 1200px) {
  .block-other-city .content-other-city h3, .block-other-city .content-other-city .h3 {
    margin-bottom: 2rem; }
  .block-other-city .other-header {
    margin-top: 3rem; } }

.block-enquiries {
  font-size: 1.125rem; }

.block-enquiries .description p {
  margin-bottom: 0.75rem;
  line-height: 2; }

.block-enquiries .content {
  position: relative;
  border-bottom: 3px solid #EFF5F9;
  margin-bottom: 2.5rem; }

.block-enquiries .content h3, .block-enquiries .content .h3 {
  color: #f37921;
  margin-bottom: 2rem; }

.block-enquiries .content .description {
  color: rgba(0, 0, 0, 0.5); }

.block-enquiries .content-enquiries {
  margin-bottom: 2.5rem; }

@media (min-width: 992px) {
  .block-enquiries .content-enquiries {
    margin-bottom: 3rem; } }

@media (min-width: 1400px) {
  .block-enquiries .content-enquiries {
    margin-bottom: 5rem; } }

.block-enquiries .other-content,
.block-enquiries .emails-list {
  margin: 2rem 0; }

.block-enquiries .emails-list {
  line-height: 2; }

.block-enquiries .emails-list a {
  color: #f37921; }

.block-enquiries .social-links {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.25rem; }

.block-enquiries .social-links a:hover {
  color: #f37921; }

@media (min-width: 768px) {
  .block-enquiries .social-links {
    width: 250px;
    justify-content: space-between;
    margin: 3rem 0; } }

@media (min-width: 992px) {
  .block-enquiries .content-enquiries .contact-emails {
    width: 55%;
    padding-right: 1rem; }
  .block-enquiries .content-enquiries .form-wrap {
    width: 45%; } }

@media (min-width: 1200px) {
  .block-enquiries .content-enquiries .contact-emails {
    padding-right: 3rem; } }

@media (min-width: 1400px) {
  .block-enquiries .content-enquiries .contact-emails {
    padding-right: 4rem; } }


.block-enquiries .content-enquiries h3,
.block-enquiries .content-enquiries .h3 {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  color: #153a5e; }

@media (min-width: 992px) {
  
  .block-enquiries .content-enquiries h3,
  .block-enquiries .content-enquiries .h3 {
    margin-bottom: 2rem; } }

@media (min-width: 1200px) {
  
  .block-enquiries .content-enquiries h3,
  .block-enquiries .content-enquiries .h3 {
    margin-bottom: 2rem; } }

@media (min-width: 992px) {
  .block-enquiries .content-enquiries {
    margin-bottom: 3rem; } }

@media (min-width: 1200px) {
  .block-enquiries .content-enquiries {
    margin-bottom: 4rem; } }

@media (min-width: 1400px) {
  .block-enquiries .content-enquiries {
    margin-bottom: 5rem; } }

.block-statement .decorate {
  background-image: url("../images/statement.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 5rem;
  margin: 1rem; }

.block-statement .content {
  padding-bottom: 2rem; }

@media (min-width: 768px) {
  .block-statement .decorate {
    background-position: left top;
    padding: 2rem;
    margin: 0 2rem 0 0; }
  .block-statement .content {
    padding-bottom: 2.5rem; } }

@media (min-width: 992px) {
  .block-statement .decorate {
    background-position: left top;
    padding: 3rem;
    margin: 0 2rem 0 0; }
  .block-statement .content {
    padding-bottom: 3rem; } }

@media (min-width: 1200px) {
  .block-statement .decorate {
    background-position: left top;
    padding: 4rem;
    margin: 0 3rem 0 0; }
  .block-statement .content {
    padding-bottom: 4rem; } }

@media (min-width: 1400px) {
  .block-statement .decorate {
    background-position: left top;
    padding: 5rem;
    margin: 0 4rem 0 0; }
  .block-statement .content {
    padding-bottom: 5rem; } }

.block-publications .content {
  margin-bottom: 3rem; }

.block-publications .content h3, .block-publications .content .h3 {
  margin-bottom: 1rem; }

.block-publications .brochure-banner .banner-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch; }

.block-publications .brochure-banner .banner-content .link {
  background: white;
  color: #f37921;
  padding: 1rem;
  border-radius: 50%;
  font-size: 2rem;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  align-self: center; }

.block-publications .brochure-banner .banner-content .image-wrap img {
  width: 100%;
  height: 100%; }

.block-publications .brochure-banner .banner-content .link:hover {
  background: #153a5e; }

.block-publications .brochure-banner .banner-content .left-wrap {
  margin-right: -30px;
  width: 24%; }

.block-publications .brochure-banner .banner-content .right-wrap {
  margin-left: -30px;
  width: 76%; }

.block-publications .slideshow-newsletter {
  position: relative; }

.block-publications {
  --swiper-navigation-size: 1rem;
  --swiper-navigation-color: white; }

.block-publications .swiper-button-next,
.block-publications .swiper-button-prev {
  padding: 1rem;
  border-radius: 50%;
  background: #F37921;
  margin-top: -32px; }

.block-publications .swiper-button-next {
  right: -0.5rem; }

.block-publications .swiper-button-prev {
  left: -0.5rem; }

@media (min-width: 992px) {
  .block-publications .swiper-button-next,
  .block-publications .swiper-button-prev {
    padding: 2rem; }
  .block-publications .swiper-button-next {
    right: -2rem; }
  .block-publications .swiper-button-prev {
    left: -2rem; } }

.block-publications .slide-content {
  display: flex;
  flex-direction: column;
  border: 1px solid #C9C9C9;
  border-radius: 0.5rem;
  padding: 1rem;
  background: white; }

.block-publications .slide-content .edition {
  width: 20%; }

.block-publications .slide-content .cover {
  width: 70%; }

.block-publications .slide-content .cover .cover-img {
  height: 0;
  padding-bottom: calc((386 / 295) * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.block-publications .slide-content hr {
  margin: 2rem 0;
  border-color: #C2C2C2; }

.block-publications .slide-content .number {
  color: #f37921;
  font-size: 1.5rem; }

.block-publications .slide-content .link:hover {
  color: #f37921; }

.block-publications .slide-content .link .icon {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #EBEBEB; }

@media (min-width: 768px) {
  .block-publications .slide-content {
    padding: 2rem; }
  .block-publications .content {
    margin-bottom: 3rem; }
  .block-publications .content h3, .block-publications .content .h3 {
    margin-bottom: 1.25rem; } }

@media (min-width: 992px) {
  .block-publications .slide-content {
    padding: 3rem 2rem; }
  .block-publications .content {
    margin-bottom: 4rem; }
  .block-publications .content h3, .block-publications .content .h3 {
    margin-bottom: 1.5rem; } }

@media (min-width: 1200px) {
  .block-publications .content {
    margin-bottom: 5rem; }
  .block-publications .content h3, .block-publications .content .h3 {
    margin-bottom: 1.75rem; } }

@media (min-width: 1400px) {
  .block-publications .content {
    margin-bottom: 6rem; }
  .block-publications .content h3, .block-publications .content .h3 {
    margin-bottom: 2.5rem; }
  .block-publications .slide-content {
    border-radius: 1.25rem; }
  .block-publications .slide-content hr {
    margin: 2.5rem 0; }
  .block-publications .slide-content {
    font-size: 1.5rem; }
  .block-publications .slide-content .edition .number {
    font-size: 1.75rem; } }

.block-publications .content .cat-nav .nav-link.active {
  border-top: 1px solid #f37921; }

.block-details {
  margin-bottom: 1rem; }

.block-details .hr {
  margin: 0.5rem 0 0.75rem;
  border-color: #D6E9FB; }

.block-details .apply-link {
  margin-top: calc(1rem + 8px); }

@media (min-width: 768px) {
  .block-details {
    margin-bottom: 2rem; }
  .block-details .hr {
    margin: 0.75rem 0 1.5rem; } }

@media (min-width: 992px) {
  .block-details {
    margin-bottom: 3rem; }
  .block-details .hr {
    margin: 1rem 0 2rem; } }

@media (min-width: 1200px) {
  .block-details {
    margin-bottom: 4rem; }
  .block-details .hr {
    margin: 1.5rem 0 2.5rem; } }

@media (min-width: 1400px) {
  .block-details {
    margin-bottom: 5rem; }
  .block-details .hr {
    margin: 2rem 0 3rem; } }

.block-details .btn-back {
  background: #F5FAFF;
  max-width: 150px;
  border-radius: 31px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.125rem; }

.block-related {
  background: #D6E9FB; }

.block-related .image-wrap {
  height: 0;
  padding-bottom: calc(280 / 750 * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.block-related .text-wrap {
  position: relative;
  padding: 1rem;
  border: 1px solid #DFDFDF;
  background: white;
  min-height: 180px; }

.block-related .text-wrap-88 {
  min-height: auto; }

.block-related .text-wrap a {
  position: absolute;
  top: -22px;
  right: 1rem;
  width: 44px;
  border-radius: 50%;
  color: white; }

.block-related .text-wrap a:hover {
  background: #153a5e;
  border-color: #153a5e; }

.slideshow-related {
  position: relative;
  --swiper-navigation-size: 1rem;
  --swiper-navigation-color: white; }

.slideshow-related .swiper-button-next,
.slideshow-related .swiper-button-prev {
  padding: 1rem;
  border-radius: 50%;
  background: #F37921;
  margin-top: -32px; }

.slideshow-related .swiper-button-next {
  right: -0.5rem; }

.slideshow-related .swiper-button-prev {
  left: -0.5rem; }

@media (min-width: 992px) {
  .slideshow-related .swiper-button-next,
  .slideshow-related .swiper-button-prev {
    padding: 2rem; }
  .slideshow-related .swiper-button-next {
    right: -2rem; }
  .slideshow-related .swiper-button-prev {
    left: -2rem; } }

@media (min-width: 1400px) {
  .block-related .content-header {
    margin-top: 0; }
  .block-related .content {
    margin-bottom: 1.5rem; }
  .block-related .text-wrap {
    padding: 1.5rem 3rem; }
  .block-related .text-wrap h3, .block-related .text-wrap .h3 {
    margin-bottom: 1.5rem;
    font-size: 1.875rem; }
  .block-related .text-wrap p {
    font-size: 1.125rem; } }

.block-404 {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black; }
  .block-404 h2, .block-404 .h2 {
    font-size: 5vw;
    color: #f37921; }

.block .html-content h1, .block .html-content .h1,
.block .html-content h2,
.block .html-content .h2,
.block .html-content h3,
.block .html-content .h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem; }


.block .html-content h4,
.block .html-content .h4 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem; }


.block .html-content h5,
.block .html-content .h5 {
  font-size: 1.75rem;
  margin-bottom: 1.5rem; }

.block .html-content h6, .block .html-content .h6 {
  font-size: 1.05rem;
  margin-bottom: 1.5rem; }

.block .html-content p {
  margin-bottom: 1.5rem;
  color: #17171B; }

.block .html-content img {
  width: 100%; }

.block .html-content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2.5rem; }

.block .html-content li {
  position: relative;
  padding-left: 2rem;
  line-height: 2; }

.block .html-content li:before {
  content: "";
  width: 14px;
  height: 2px;
  background: #f37921;
  margin-right: 0.5rem;
  position: absolute;
  left: 0;
  top: 17px; }

@media (min-width: 768px) {
  .block .html-content {
    font-size: 1.125rem; }
  .block .html-content p {
    text-align: justify;
    margin-bottom: 2rem; } }

@media (min-width: 992px) {
  .block .html-content {
    font-size: 1rem; } }

@media (min-width: 1400px) {
  .block .html-content ul {
    margin-bottom: 3rem; } }
